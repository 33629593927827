#activeLetter{
  color: var(--fill-green);
  font-weight: bold;
}

.dictionaryEntry{
  cursor: pointer;
  color: var(--dark-gray);
  margin: 0px 2px;
  line-height: 18px;
}
