.download-OptionsContainer{
  position: absolute;
  background-color: var(--white);
  padding: 8px 10px;
  display: flex;
  align-items: center;
  z-index: 50;
  font-size: 12px;
  text-align: center;
}
.download-asPDFButton{
  color: var(--dark-gray);
  background-color:var(--row-fill-gray);
  padding: 3px 6px;
  margin: 1px 2px;
  border-radius: 7px;
  width: 80px;
  cursor: pointer;
  height: 40px;
}
.download-asOriginalButton{
  color: white;
  background-color:var(--fill-green);
  padding: 3px 6px;
  margin: 1px 2px;
  border-radius: 7px;
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

}
#buttonDownload-Icon{

  position: relative;
}
.download-closeButton{

  align-self: flex-start;
  cursor: pointer;
  margin: 0 0 5px 5px;
  width: 12px;
  text-align: center;
}
