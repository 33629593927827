.centered-item{
    margin: 0 auto;
}
.release-criteria-edit{
    display: block;
    width: 100px;
  border-radius: 6px;
  font-size: 18px;
  border:1px solid gray;
  max-width: 100%;
  word-break: break-all;
}
.release-criteria-cell{
    width: 100px;
}