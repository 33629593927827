.left-box-ingredientButton{
    border-right: 1px solid var(--light-gray);
    padding: 0 5px;
}
.ingredientCreate-container{
	display: flex;
    flex-direction: row;
}
.ingredient-property-container{
    text-align: left;
    font-size:16px;
    line-height:18px;
    margin: 6px 0;
    cursor: pointer;
}
.ingredientTemplateContainer{
    padding: 0 5px;
    margin: 0 5px;
}
.ingredientTemplateContainer-title{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size:24px;
}
.template-attributeEditButtonContainer{
    display: flex;
    justify-content: space-between;
    font-size:14px;
}
.ingredientTemplateRow-container{
    border-bottom: var(--border-gray);
    margin: 0 0 5px 0;
    padding: 5px 5px;
}
#ingredientTemplate-selected{
    border:1px solid var(--fill-green);
    border-radius: 6px;
}
.ingredientTemplateRow-delete{
    font-size:14px;
    cursor:pointer;
    color:var(--fill-green)
}
.ingredient-clearCurrentTemplateSelection{
    font-size:14px;
    color:var(--fill-green);
    cursor:pointer;
}
.ingredientTemplateRow-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.new-template-input{
    height:30px;
    width: 100%;
    margin: 5px 0px;
}
.ingredient-template-button-new{
    font-size:16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.make-new-template-title{
    cursor: pointer;
    color:var(--fill-green)
}