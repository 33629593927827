.form-button {
  border: var(--border-gray);
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 #DEDEDE;
  height: 67px;
  width: 100%;
  font-size: 30px;
  line-height: 67px;
  text-align: center; }
  .form-button:hover {
    cursor: pointer; }
.signup-emailText{
  margin: auto;
  text-align: center;
}
.primary {
  margin: 24px 0; }

.login-logo{
  width:60%;
  align-self: center;
}
.secondary {
  background-color: var(--secondary-button-gray);
  color: #4A4A4A; }

.authform-body {
  padding: 40px 20px 30px 20px;
  background-color: white;
  width: 366px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;}
  .authform-body a {
    text-decoration: none; }
  .authform-body .register-text {
    text-align: center;
    color: #4A4A4A;
    font-size: 20px; }
  .authform-body .signup-header {
    font-size: 39px;
    text-align: center;
    margin: 36px 0 36px 0; }
  .authform-body .signup-hint {
    align-self: flex-end;
    font-size: 14px;
    color: #9B9B9B; }

.label-group {
  display: flex;
  justify-content: space-between; }

.input-text {
  border-radius: 8px;
  border: var(--border-gray);
  height: 40px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px; }

.input-label {
  display: inline-block;
  font-size: 20px;
  margin-top: 17px; }

.authform-large {
  width: 487px;
  background-color: white;
  margin: 20px auto 40px auto;
  padding: 20px;
  text-align: center; }
  .authform-large a {
    text-decoration: none; }
  .authform-large .access-code-input {
    height: 54px;
    font-size: 40px; }

.create-org {
  width: 430px;
  background-color: #F7F5F5;
  color: #404040;
  margin-bottom: 10px;}
.organization-create-or-container{
  margin: 10px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.organization-create-or{
  margin: 0 auto;
}
.organization-create-line{
  border-top: 1px solid gray;
  opacity:.4;
  width: 40%;
}
.create-org-header {
  font-size: 32px;
margin-top: 10px;}

.join-org-header {
  font-size: 26px;
margin-top:10px;}
.password-reset{
  text-align: center;
}
.join-org {
  text-align: center; }
  .join-org .join-org-subheader {
    font-size: 26px;
    margin-top: 10px; }
  .join-org .input-label {
    font-size: 20px; }

.form-separator-half {
  display: inline-block; }

.helpText-confirmation{
  vertical-align: middle;
  text-align: center;
  padding: 50px;
  width:400px;
  margin: auto;
  background-color: white;
}
