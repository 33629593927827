.TagAccessModalContents{
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  color: black;
}
.TagAccessModalContents-tagsDictionary{
  width: 50%;
  border-right: var(--border-gray);
  padding: 10px;
  box-sizing:border-box;
}
.TagAccessModalContents-availableUsers{
  width: 50%;
  padding: 10px;
  max-height: 1000px;
  overflow-y: auto;
}
.tagAccess-activeManipulation{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--fill-green);
  color:black;
}
.tagAccess-buttonsContainer{
  display: flex;
  flex-direction: column;
}
.tagAccess-activeUsers{
  width: 40%;
  box-sizing: border-box;
  padding: 5px;
}
.tagAccess-activeTagsContainer{
  width: 40%;
  padding: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.tagAccess-activeTags{
  min-width: 100px;
  display:flex;
  flex-wrap:wrap;
}
.IndividualUserTagsAccess-information{
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  justify-content: space-between;
}
.ToggleFullTagAccessButton{
  display: flex;
  font-size: 14px;
  color: var(--dark-gray);
  cursor:pointer;
  align-items:center;
  justify-content: center;
}
.UserTagsAccess-helpText{
  font-size: 12px;
  color: var(--dark-gray);
}
.IndividualUserTagsAccess-container{
  margin-bottom: 20px;
  cursor: pointer;
}
.SelectedUserTagAccess-info{
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  justify-content: space-between;
}
.SelectedUserTagAccess-rightSide{
  display: flex;
  flex-wrap: nowrap;
}
.tagAccess-removeSelectedUser{
  cursor: pointer;
  color: var(--dark-gray);
  margin-left: 5px;
}
.darkGrayButton{
  background-color: var(--dark-gray);
  color: white;
}
.selectedTag-tagAccessContainer{
  display: flex;
  flex-wrap: nowrap;
    border: var(--border-gray);
    background-color: var(--light-gray);
    font-size: 12px;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    padding: 0.5px 5px;
    border-radius: 5px;
    margin: 2px 3px;
    max-width: 100%;
    word-break: normal;
    white-space: nowrap;
    word-wrap: break-word;

}
.greenButton{
  background-color: var(--fill-green);
  color: white;
}
.tagAccess-removeTag{
  margin-left: 5px;
}
.changeTagAccessButton{
  border:none;
  border-radius: 5px;
  padding: 5px;
  width: 90px;
  margin: 5px 0;
}
#RemoveTagAccessButton{
  border:var(--border-gray);
}
.tagAccess-userContainer{
  min-height: 100px;
}
.TagAccess-tagsDictionaryContainer{
  font-size: 16px;
}
.tagAccess-clearButton{
  font-size: 12px;
  color: var(--fill-green);
  cursor: pointer;
  margin-left: 10px;
}
.tagAccess-activeHeaderContainer{
  display: flex;
  align-items: baseline;
  justify-content: center;
}
