.topTag-container{

  border: var(--border-gray);
  border-radius: var(--container-corners);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 5px;
}
.topTag-container:hover{
  background-color: var(--fill-green);
  color: var(--white);
}
#notAccessibleTag{
  color:gray;
}
