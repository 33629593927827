
.toggleSwitchContainer{
  width: 30px;
  height: 15px;
  display: flex;
  background-color: var(--light-gray);
  border:var(--border-gray);
  border-radius: 15px;
  padding: 1px;
}
.activeSwitch{
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: var(--white);
  align-self:flex-end;
}
.inactiveSwitch{
  width: 15px;
  border-radius: 15px;
  align-self:flex-start;
  height: 15px;
  background-color: var(--white);
}
#activeSwitch{
  flex-direction: row-reverse;
  background-color: var(--fill-green);
}
#inactiveSwitch{
  flex-direction: row;
}
