:root{
  --border-green:3px solid #77BA43;
  --fill-green:rgb(36,164,81);
  --border-gray:1px solid #EFEFEF;
  --row-fill-gray:#F4F4F4;
  --dark-gray:#939393;
  --light-gray:rgb(245,245,245);
  --white:white;
  --container-corners:14px;
  --container-corners-left:0 14px 14px 14px;
}
.pointer{
  cursor: pointer;
}
.toast-container{
  z-index: 9999;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.is-spaced-between{
  display: flex;
  justify-content: space-between;
}
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid var(--fill-green);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--fill-green) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loaderContainer{
  align-self: center;
  margin: 0 auto;
}
.App {
  text-align: center; }
input, textarea{
  font-family: 'Rubik','Catamaran', sans-serif;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px; }
.boxShadow{
  box-shadow: 0 2px 2px 0 #DEDEDE;
}
.is-spaced-between{
  display: flex;
  justify-content: space-between;
}
.smallHelpText{
  color: var(--dark-gray);
  font-size: 12px;
}
.is-clickable{
  cursor: pointer;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

.content {
  display: flex;
  background-color: var(--row-fill-gray);
  min-height: 100vh;
  flex-direction: column;
  position: relative; }

.content-body {
  flex: 1;
padding: 20px;}

.section-header {

  text-align: center;
  color: white;
  background-color: var(--fill-green);
  font-size: 36px;
  line-height: 74px;
  height: 74px;
  border-radius: 8px 8px 0 0; }

.primary{
  background-color: var(--fill-green);
  color: white;
}
.container{
    border: var(--border-green);
    border-radius: var(--container-corners);
}
.container-left{
    border: var(--border-green);
    border-radius: var(--container-corners-left);
}
.noselect{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.wordbreak{
  word-wrap: break-word;
  word-break: break-word;
}
.table-row{
  border: var(--border-gray);
}
.table-row:nth-child(even){
  background-color: var(--row-fill-gray);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
