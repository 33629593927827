.TimeDiff-container{
  color:black;
  font-size: 18px;
  margin-bottom: 40px;
}
.TimeDiff-title{
  font-size: 22px;
}
.smallTimeDiff-container{
  
}
