.ContactsSuggestionRow{
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 10px;
  color: black;
}
.ContactsSuggestionRow-leftContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ContactsSuggestionRow-name{
  font-weight: bold;
}
.ContactsSuggestionRow-organization{
  font-size: 14px;
  padding-left:10px;
}
.ContactsSuggestionRow-email{
  list-style-type: none;

}
.react-tagsinput-input{
  width: 90%;
  text-align:center;
}
.react-autosuggest__suggestions-list{
  list-style-type: none;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-left: 0px;
}
.react-autosuggest__suggestion--highlighted {
  border: 1px solid var(--fill-green);
  color:white;
}
.react-tagsinput--focused{
  padding: 0;
}
