.ingredient-addToRecipe {
    color: var(--fill-green);
    cursor: pointer;
    width: 100px;
}

.ingredientRecipe-currentlySelected {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ingredientRecipe-headerText {
    color: var(--dark-gray);
    font-size: 14px;
}

.recipe-deleteIngredient-confirm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 3px;
    width: 160px;
}

.addIngredient-searchContainers {
    display: flex;
    margin-top: 30px;
    padding-top: 30px;
    border-top: var(--border-gray)
}

.addIngredient-ingredientsContainer {
    width: 70%;
    max-width: 70%;
    padding: 10px 50px 10px 50px;
    box-sizing: border-box;
}

.addIngredient-tagsContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 30%;
}

.addIngredient-tagsNavigator {}

.addIngredient-activeTags {}

.addIngredientButton {
    background-color: var(--fill-green);
    border-radius: 4px;
    color: var(--white);
    width: 80px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    height: 45px;
    margin-right: 10px;
    line-height: 35px;
}

.recipe-edit-button-confirm {
    background-color: var(--fill-green);
    color: white;
    border-radius: 4px;
    width: 75px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    height: 40px;
    margin-right: 10px;
    line-height: 40px;
}

.recipe-edit-button-cancel {
    background-color: var(--light-gray);
    border-radius: 4px;
    width: 75px;
    font-size: 16px;
    color: var(--dark-gray);
    cursor: pointer;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

#confirm-cancel-leftbutton {
    margin-right: 10px;
}