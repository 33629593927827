
.preview{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.45);
  padding: 50px;
  z-index: 9999999;
  color: black;
  display: flex;
  flex-direction: column;
  background-size: cover;
  box-sizing: border-box;
}
.imagePreviewer-container{
  max-width: 80%;
  max-height:80%;
  margin: 0 auto;
}
.preview-png-previewer{
  display: flex;
  justify-content: center;
}
.button-preview-documentViewer{
  order:1;
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--fill-green);
  align-self: center;
  width: 40px;
  height: 40px;
}
.preview-errorText{
  color: white;
}
.preview-png{
  z-index: 9999999;
  position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
}
.closePreview{
  background-color: transparent;
  border:none;
  font-size: 20px;
  position: absolute;
  color: white;
  top:20px;
  right: 20px;
}
.button-closeDocPreview{
  order:3;
  margin-left:8px;
  cursor: pointer;
  outline: none;
  }
div.pg-viewer-wrapper{
  overflow-y: auto;
}
.react-pdf__Page__canvas{
  margin: auto;
}
.button-preview-templates{
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--dark-gray);
  margin: 0 7px;
}
.button-preview{
  order:1;
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--fill-green);
  align-self: center;
}
.pdf-pages{
  display: flex;
  align-items: center;
  margin: 0 auto;
  align-self: center;
  color: white;
  vertical-align:middle;
  z-index: 100;
}
.pdfPageButtons{
  width: 30px;
  margin: 5px;
}
.previewHelpText{
  text-align: center;
  color: white;
  opacity: .6;
}
.pdfCloseButton{
  margin: 10px auto;
  color: white;
}
.loadingPDFMessage{
  color: white;
  text-align: center;
  vertical-align: center;
}
