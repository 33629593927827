.header {
  background-color: white;
  color: black;
  height: 40px;
box-shadow: 0 2px 4px 0 #DEDEDE;
display: flex;
flex-wrap: nowrap;
justify-content: space-between;}
  .header a {
    text-decoration: none;
    color: black;
  height: 100%;}
  .header .header-link {
    font-size: 18px;
    line-height: 40px;
    padding: 0 15px;
    height: 40px;
    box-sizing: border-box;
  }
  .header .header-link:hover {
    border-bottom: 2px solid var(--fill-green)}
  .header .header-left {
    display: flex;
    position: absolute;
    left: 0;
    margin: 0 15px;
    white-space: nowrap; }
  .header .header-right {
    display: flex;
    position: absolute;
    right: 0;
    margin: 0 15px; }
    .header-logo-unauth{
      height:40px;
      margin: 10px auto;
    }
  .header .header-logo {
    height: 26px;
    transform: translateY(5px); }

.current {
  border-bottom: 2px solid var(--fill-green)}

.header-unauth {
  height: 65px;
  background-color: var(--white);
  border: 1px solid #979797;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-gray);
  padding: 0 20px;
  vertical-align: middle;
}
.header-email{
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.introJs-header-notifications{
  position:relative;
}
.unreadNotifications{
  display: inline;
  font-size: 16px;
  border-radius: 5px;
  margin-left: 5px;
  height: 15px;
  padding: 2px 10px;
  background-color: var(--fill-green);
  color: white;
}
#introJs-header-permissions{
  max-width: 150px;

}
.header-login{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header-login-itemContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
}
.header-input-text{
  border-radius: 2px;
  border: var(--border-gray);
}
.header-register-text{
  color:var(--dark-gray);
  cursor: pointer;
  font-size: 10px;
}
.header-input-label{
  font-size: 12px;
}
.header-errorText{
  font-size: 12px;
  background-color: white;

}
.header-unauth-left{
  display: flex;

}
#header-signup-button{
  padding: 3px 7px 4px 7px;
  width: 100%;
  background-color: var(--fill-green);
  margin: auto 0;
  color: var(--white);
  border-radius: 4px;
  border: none;
  white-space: nowrap;
}
#header-login-button{
  padding: 3px 7px 4px 7px;
  width: 100%;
  border: 1px solid var(--fill-green);
  margin: auto 10px;
  color: var(--fill-green);
  border-radius: 4px;
  white-space: nowrap;
}
@media screen and (max-width:950px){
  .header .header-right{
    display: none;
  }

}
#header-features-button{
  color: var(--dark-gray);
  margin: auto 10px;
}
