.interactiveDemoContainer{
  width: 620px;
  padding: 20px;
  height:350px;
  position: relative;
}
.interactiveDemoContainer-container{
  background-color: white;
  margin: 20px 5%;
  min-width: 620px;
  padding: 0 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.opaqueDemoObject{
  opacity: 1;
}
.interactiveDemo-sectionTitle{
  font-size: 32px;
  padding: 12px;
  font-weight: 600px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  background-color: #fafafa;
  color: #151515;
  border-bottom: 1px solid #d3d3d3;
  font-weight:600px;
  width: 100%;

}
.interactiveDemo-useCases{
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin:0  5% ;
  align-items: center;
  justify-content: center;

}
.interactiveDemo-useCase-container{
  text-align: center;
  width: 30%;
  margin: 40px auto 0 auto;
  background-color: white;
  padding-bottom:5px;
  box-sizing: border-box;
  min-width: 300px;
}
.interactiveDemo-useCase-header{
  font-size: 24px;
  background-color: #fafafa;
  color: #151515;
  padding: 0 10px;
  border-bottom: 1px solid #d3d3d3;
}
.interactiveDemo-useCase-text{
  padding: 25px;
}
.transparentDemoObject{
  opacity: .4;
}
.demoDoc{
  position: relative;
}
.demoTag{
  cursor: pointer;
}
.interactiveDemo-topTags, .interactiveDemo-bottomTags{
  position: relative;
  display: flex;
  height: 100px;
}
.interactiveDemo-text{
  font-size: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popupClickTag{
  background-color: var(--fill-green);
  height: 26px;
  color:white;
  margin: 0 auto;
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 100;
}
.interactiveDemo-text-instructions{
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.interactiveDemo-docs{
  display: flex;
  justify-content: space-between;
}
.demoRedTag{
  left: 0px;
  z-index: 10;
  position: absolute;
}
.demoGreenTag{
  position: absolute;
  right: 40px;
}
.demoPlantsTag{
  position: absolute;
  right: 30px;
}
.demoFruitTag{
  position: absolute;
  left: 20px;
}
.landing-goFeaturesButton{
  background-color: var(--fill-green);
  color:white;
  border-radius: 4px;
  width: 125px;
  margin: 0 auto;
  cursor: pointer;
}
