.notificationsheader{
  font-size: 26px;
  height:40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  white-space: nowrap;
}
.notifications-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-allUnsignedSignatures{
  font-size: 12px;
  color: var(--fill-green);
  width: 75px;
  white-space: normal;
  text-align: center;
  height: 50px;
  cursor:pointer;
  display: flex;
  align-self: flex-start;
}
.summaryNotifications-seeMore{
  text-decoration: none;
  color: black;
  opacity: .6;
  align-self: center;
  outline:none;
  line-height: 40px;
}
.notifications-rightContainer{
  max-width: 600px;
}
.button-markAllRead{
  font-size: 16px;
  margin-left: 20px;
  cursor: pointer;
}
.notifs-docs-tableheader-expires{
  margin: 0 15px 0 120px;
}
.notifications-container{
  padding: 20px;
  margin: 20px;
  background-color: white;

}
.notifications-left{
  flex:1;
}
.notifications-masterContainer{
  display: flex;
  flex-wrap: none;
}
.notifications-permissions{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
