.features-sectionContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 20px 30px;
  align-items: center;

}
.features-pageContents{
  margin: 0 5%;
}
.public-faq-container{
  background-color: white;
  margin:30px 5%;
  padding: 30px 10%;
}
.features-largeGraphic-header{
  font-size: 32px;
}
.features-largeGraphic-textContainer{
  margin: 0 auto;
}
.features-documentFeatures{
  max-width: 60%;
  margin: 0 auto;
}
.features-tagsSection-header{
  margin: 30px 0 0 0;
  font-size: 32px;
}
.features-documentFeaturesGraphic{
  display: flex;
  align-items: center;
  margin: 20px 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.features-largeGraphic-text{
  padding: 5px 30px;
  font-size: 1rem;
  min-width: 300px;
  max-width: 400px;
}
.features-sectionTitle{
  background-color: white;
  font-size: 32px;
  padding-top: 30px;
}
.features-pageContents{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.features-banner-container{
  margin: 0 5%;
  background-color: white;
}
.features-banner-image{
  width: 100%;
}
.features-wantMore{
  padding: 0 30px;
}
.features-signup-button{
  text-decoration: none;
  padding: 3px 7px 4px 7px;
  width: 80px;
  background-color: var(--fill-green);
  margin: 10px auto;
  color: var(--white);
  border-radius: 4px;
  border: none;
  font-size: 20px;
  white-space: nowrap;
}
.features-section{
  background-color: white;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  max-width: 450px;
  min-width: 300px;
  padding: 0 10px;
  margin: 0 auto;
}
.features-sectionTextItem{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.features-headerText{
  font-size: 20px;
}
.features-paragraphText{
  color:var(--dark-gray);
}
.features-Icon{
  width: 100px;
}
