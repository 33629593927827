.priceTable {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.showPriceTable {
    color: var(--fill-green);
    cursor: pointer;
}

.price-totalRow {
    border-top: 1px solid gray;
}

.bom-units {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.recipes-unitChanger-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    align-items: baseline;
}

.priceTable-container {
    margin: 15px 0;
}

.recipes-unitChangerInput {
    width: 50px;
    text-align: center;
    margin: 0px 5px;
}

.recipes-unitChanger-individual {
    margin: 2px 5px;
    display: flex;
    min-width: 175px;
    align-items: baseline;
}

.priceTable-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.priceTable-header-right {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    align-items: baseline;
}

.recipes-unitChanger-button {
    background-color: var(--fill-green);
    cursor: pointer;
    color: white;
    border-radius: 3px;
    padding: 5px 6px;
}