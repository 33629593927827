.notification-item{
  border-bottom: var(--border-gray);

  display: flex;
  padding: 10px ;
  width:100%;
  cursor: pointer;
  box-sizing: border-box;
}
.notification-item-summary{
    padding: 12px 10px;
    cursor: pointer;
    border-bottom: var(--border-gray);
    display: flex;
    align-items: center;
    width:100%;
    box-sizing: border-box;
    font-size: 14px;
}
.notification-row-header{
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}
.notification-icon{
  display: inline-block;
  height:45px;
  fill:var(--fill-green);
  vertical-align: middle;
  text-align: center;

}
.notification-row-date{
  color: var(--dark-gray);
  margin: 0 4px;
  font-size: 12px;
}
.notification-row-name{
  font-weight: bold;
}
.notification-dateIcon{
  display: flex;
  margin-right: 5px;
  white-space: nowrap;
  align-items: center;
  flex-direction: column;
}
.notification-description{
  flex:2;
  word-break: break-word;
}
.helpText-notifications{
  font-size: 14px;
  line-height: 14px;
}

.notification-read{
  border-radius: 5px;
  height: 10px;
  width: 10px;
  background-color:var(--dark-gray);
}
.notification-unread{
  border-radius: 5px;
  height: 10px;
  width: 10px;
  background-color: var(--fill-green);
}
