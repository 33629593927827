.PermissionsNavigator-container{
  display: inline-flex;
  flex-direction: column;
}
.PermissionsNavigator-submenu{
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  font-size: 14px;
  line-height: 24px;
  z-index: 100;
  background-color: white;

}
a.templates-submenu-links{
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.dropdownTriangle{
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;

  border-top:8px solid var(--fill-green);
}
