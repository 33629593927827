.featuresList-featureTitle{
  font-size: 20px;
  border-bottom: 1px solid var(--fill-green);
  margin-top: 20px;
}
.featuresList-title{
  font-size: 30px;
}
.featuresList-featureText{
  text-align: left;
}
.featuresList-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 20px 30px;
  margin: 40px 5%;
}
