.tagEditor-currentlySelectedHeader{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tagEditor-currentlySelected-name{
  display: flex;
  justify-content: space-between;
}
.tagUtilityButtons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.tagRenameButtons{
  font-size: 11px;
  color: var(--fill-green);
  display: flex;
  align-items: center;
  border: none;
}
.tagDropZone{
  height: 500px;
}
.saveTagRenameButton{
  color: var(--fill-green);
  cursor:pointer;
  margin-right: 10px;
  background-color: none;
  border:none;
  font-size: 12px;
  font-weight: bolder;
}
.renameTagButton{
  border: none;
  color: var(--fill-green);
  cursor: pointer;
  background-color: transparent;

}
.tagsEditor-contentHeader{
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border-gray);

}
.buttonMergeTag{


  display: flex;
  align-items: center;
  justify-content: center;
}
.mergeButton{
  white-space: nowrap;
  color: var(--fill-green);
  font-size: 11px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.tagsEditor-tableHeader-active{
  color: var(--fill-green);
  border-bottom: 2px solid var(--fill-green);

}
.currentTagName{font-size: 24px;}
.tagsEditor-tableHeader-inactive{
  color: var(--dark-gray);

}
.tag-ancestry-label{
  color:var(--dark-gray);
  font-size: 14px;
}
.tagEditor-container{
  margin-left: 10px;
  height: 100%;

}
.tagsEditor-contents{
  overflow-y: auto;
  max-height: 75%;
}
