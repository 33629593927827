.logs{
  background-color: white;
  margin: 20px;
  padding: 20px 30px;
}
.logs-container{
  display: flex;
}
.logsheader#logs-header {
  font-size: 26px;
  height:50px;
  white-space: nowrap;
}
.log-info-field{
  border-radius: 5px;
  border: var(--border-gray);
  width: 100%;
  resize: vertical;
}
.log-row {
    padding: 2px 10px;
    min-height: 40px;

    font-size: 14px;
    font-weight: 300;
    color: #000000;
    display: flex;
  align-items: center;}
.log-row-container{
  border-bottom: var(--border-gray);
}
.log-text {
  margin-left: 10px;
  display: inline-block;
  line-height: 16px;
  width: 600px; }
.logsTableHeader{
  border-bottom: var(--border-gray);
  color:var(--dark-gray);
}
.logsTableHeader-action{
  width:645px;
  margin-left: 10px;
}
.logsTableHeader-timestamp{
  width:200px;
  text-align: center;
}
.logsTableHeader-user{
  text-align: center;
  width:150px;
}
.log-user{
  width:150px;
}
.log-icon {
        height: 35px;
      width: 35px;}
.log-timestamp{
  width: 200px;
  text-align: center;
}
.log-user{
  width:150px;
  text-align: center;
}
.log-notes{
  background-color: var(--row-fill-gray);
  width: 100%;
  margin-bottom: 5px;
  padding: 0 5px;
}
.log-messageContainer{flex:1;}
.logSummary{
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
}
.summaryLogs-seeMore{
  text-decoration: none;
  color: black;
  opacity: .6;
  align-self: center;
  outline:none;
  line-height: 40px;
}
.logsTableHeader{
  display: flex;
}
