.priceInfoInput {
    display: flex;

    align-items: center;
}

.priceInfoContainer {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
}

.ingredient-used-in-container {
    overflow-y: scroll;
}

.priceInfoItem {
    margin-right: 3px;
}

.ingredient-used-in-ratio {
    display: flex;
    align-items: center;
}

.small-lock {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}