.upload-container {
  background-color: var(--white);
  margin: 20px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  padding: 20px;
  }
.document-upload-contents{

    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .error-file-upload{
    color:#cc3300;
  }
  .upload-signatureContainer{
    margin-top: 20px;
  }
.selectedSignatureList{
  width: 300px;
}
.documentUpload-signatureSelection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
.document-upload-header{
  font-size: 24px;
  height:40px;
  position: relative;
}
.navigation-container {
  width: 80%;
  min-height: 700px;
  border: var(--border-green); }

.document-tree {
  min-height: 600px; }

.file-dropzone-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  text-align: center;
  width: 350px;
  flex-grow: 2;
  max-width: 40%;
  margin:0 10px;
  min-width: 350px;
vertical-align: middle;}
.folder-dropzone-container{
  display: flex;
  flex-direction: column;
  font-size: 24px;
  min-height: 200px;
  min-width: 350px;
  width: 350px;
  max-width: 40%;
  flex-grow: 1;
  margin-right: 10px;
  background-color: var(--row-fill-gray);
  border: 1px dashed gray;
  border-radius: 10px;
  color: #9B9B9B;
  align-items: center;
  justify-content:center;
}
.document-required-info{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
.requiredInfoText{
  align-self: flex-start;
  max-width: 200px;
}

#documentsUploadActive{
  color: var(--fill-green);
  font-weight: bold;
}
.uploadClearFiles{
  ;
  text-align: center;
  cursor: pointer;
  width: 150px;

}
  .file-dropzone {
    border: 1px dashed gray;
    min-height: 200px;
    line-height: 200px;
    border-radius: 10px;
    box-sizing: border-box;

    padding: 0 30px;
  background-color: var(--row-fill-gray)}
    .file-dropzone-text {
      padding: 20px;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      font-size: 16px;
      text-align: center;
      color: #9B9B9B;
      opacity: 1; }
#onUpload{
  display: block;
  vertical-align: middle;
  width:300px;
  font-size: 24px;
  margin: 20px auto;
}
.upload-date{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
}
.upload-optional-info-header{
  margin: 20px 20px 0 0 ;

}
.upload-expiresBoolean{
  align-self: center;
}
.helpText-docCategory{
  display: inline-block;
  font-size: 14px;
  opacity: .6;
}

.upload-optional-info{
  display: flex;
  width: 100%;
  margin-top: 40px;

}
.upload-info-field{
  border-radius: 3px;
  border:1px solid var(--dark-gray);
  height: 30px;
}
.upload-tags{
  max-width: 50%;
  flex-grow: 1;
  margin: 0 30px;
}
.upload-label{
  display: block;
  font-size: 20px;
}
.upload-optionalInfo-top{
  display: flex;

}
.uploadToggleButton{
  border-radius: 6px;
  height:40px;
  font-size: 20px;
  position: absolute;
  right:10px;
  top:0;
  display: inline;
  line-height: 40px;
  color: white;
  text-decoration: none;
}
