
.tags-parents{
  order:3;
  border-left:var(--border-gray);
  max-width: 250px;
  min-width: 150px;
  flex:1;

}
.tags-left{
  width:600px;
  margin: 10px 20px;
  padding:20px;
  background-color: white;
  min-height: 90vh;
  max-width: 600px;
  min-width: 600px;
}
.tags-utilityButtons{
  display: flex;
}
.tagDropZone{
  border-left: var(--border-gray);
  height: 400px;
}
.button-addtag{
  font-size: 16px;
  color: white;
}
.tags-header-text{
  font-size: 24px;
  display: flex;
}
.tags-left-navigator{
  max-width: 400px;
  min-width: 250px;
  border-right: var(--border-gray);
}
.tags-children{
  order:4;
  border-left:var(--border-gray);
  max-width: 250px;
  min-width: 150px;
  flex:1;
}
.subtags-container{
  padding: 10px 15px;
}
.tags-middle-header{
  background-color: var(--fill-green);
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.tags-middle-header-text{
  font-size:24px;
}
.tags-viewer-container{
  display: flex;
  flex-direction: column;
}
.tags-left-manipulator{
  width: 400px;
  height: 100%;
}
#subtags-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#subtags-name{
  order: 1;
}
#subtags-button{
  order: 2;
}
.tags-middle-documents{
    background-color: white;
    margin: 10px 50px 10px 10px;
    padding: 20px;
    box-sizing: border-box;
}
.tags-right{
  width: 700px;
  max-width: 700px;
  min-width: 700px;
}
.tags-middle-docs-list{
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px;

}
#tags-middle-logs{
  max-height:500px;
}
.modalText{
  color:black;
}
.selectedDocuments{
  margin:0 10px;
  order:2;
  display: flex;
}
.tags-left-tagsContainer{
  display: flex;
  height: 100%;
}
.tags-docs-tableheader-name{
width: 161px;
}
.tags-docs-tableheader-expires{
  margin: 0 15px 0 80px;
}
.tags-docs-table{
  border-bottom: var(--border-gray);
  color: var(--dark-gray);
  display: flex;
  font-size: 12px;
}
.selectedDocText-tags{
  font-size: 18px;
  line-height: 40px;
}
.tags-group-buttonOperations-introJScontainer{
  display: flex;
  justify-content: center;
}
