.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 1000;}
.fixed-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    z-index: 1000;}
#backdropInternalSelect{
  height: auto;
  bottom: auto;
}
@media screen and (min-height:1050px){
  #backdropInternalSelect{
    bottom: 0;
  }
}
.modal {
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 1000px;
  width: 825px;
  min-height: 250px;
  margin: 0 auto;
position: relative;}
.fixed-modal {
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 1000px;
  width: 825px;
  min-height: 250px;
  margin: 0 auto;
position: relative;}
#modalExtraWide{
  width: 85%;
  max-width: 90%;
}
  .modal-title {
    text-align: center;
    font-size: 36px;
    padding-top: 30px;
    color: black;
  font-weight: bold;}
  .modal-body {
    padding: 15px;
    font-size: 20px;
    white-space: normal;
  text-align: center;}

.modal-close{
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--dark-gray);
  border: none;
  background-color: none;
  font-size: 20px;
  cursor:pointer;
}
.modal-button{
  padding: 8px 20px;
  margin: 20px auto;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor:pointer;
}
