.tagDictionary-alphabet {
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
}

.tagDictionary-topTagButton {
  cursor: pointer;
}

.tags-dictionary-header {
  box-sizing: border-box;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: white;
  background-color: var(--fill-green);
  line-height: 40px;
}

.tagDictionary-headerButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: var(--dark-gray);
}

.tagDictionary-toggleSwitch {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.tagDictionary-container {
  margin-top: 8px;
}

.tagDictionary-filterLabel {
  margin-right: 3px;
}

#activeTextGreen {
  color: var(--fill-green);
}