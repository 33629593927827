.smallButton {
    width: 75px;
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin: 3px;
}

.recipeSettings-inputContainer {
    width: 400px;
}

.smallButtonContainer {
    display: flex;
}

.smallButton-secondary {
    background-color: darkgray;
    color: white;
}

.recipeSetting-container {
    margin: 10px 0;
}

.recipeSetting-title {
    font-size: 20px;
}