.BOM-viewerpage-container {
    display: flex;
    flex-direction: row;
}

.recipeInfo {
    display: flex;
    flex-direction: column;
}

#bom-log-container {
    display: flex;
    flex-direction: column;
}

.BOM-viewerPage-majorContainer {
    display: flex;
}

.BillOfMaterials-logs-headers {
    display: flex;
    justify-content: space-between;
}

#bom-log-majorcontainer {
    margin-left: 15px;
}