.toggleDensity-checkboxContainer {
    display: flex;
    align-items: baseline;
    margin-left: 30px;
}

.density-button {
    color: var(--fill-green);
    cursor: pointer;
}

.toggleDensity-buttonContainer {
    display: flex;
    align-items: center;
    margin: 0;
}

.toggleDensity-button {
    width: 75px;
    height: 26px;
    margin: 0 3px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    align-items: baseline;
}

.button-secondary {
    background-color: darkgray;
    color: white;

}