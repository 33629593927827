.batchSheetInput-name{
  width: 100%;
  border-radius: 3px;
  border:1px solid var(--dark-gray);
  height:40px;
  font-size:16px;
}
.batchSheetSend-modalContainer{
  padding:0 40px;
}
.batchSheetSend-unitContainer{
  display: flex;
  align-items:center;
  margin: 0 auto;
  width:180px;
}