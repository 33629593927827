.settings
{
  margin: 30px;
}
.settings .settings-section .settings-heading {
  font-size: 24px;
  line-height: 20px;
}

#deactivatedUser{
  color:gray;
}
.individualNotificationSettings-title{
  border-bottom: var(--border-gray);
  font-size: 20px;
  margin-top: 15px;
}
.settings .settings-section .settings-section-divider {
  border: 1px solid var(--fill-green); }
.settings-section{
  background-color: white;
  padding: 40px 40px 20px 40px;
  margin-bottom: 20px;
}
.password-reset-settings{
  color:black;
  text-decoration: none;

}
.deactivatedUserContainer{
  display: flex;
  flex-direction: column;

}
.showDeactivatedUserButton{
  cursor: pointer;
  color: var(--fill-green);

}
.deactivatedUsersList-Container{
  width: 600px;
  color:var(--dark-gray);
}
.user-list-row{
  display: flex;
  justify-content: space-between;
}
.inviteUserButton{
  background-color: var(--fill-green);
  border-radius: 3px;
  padding: 4px 6px;
  cursor: pointer;
  color:white;
  border:none;
  font-size: 14px;
  margin-right: 10px;
}
.organizationButtons{
  display: flex;
}
.settings .settings-section .account-info-row {

  width: 650px;
  display: flex;
  flex-direction: column;
  }
.account-info-row-first,.account-info-row-second {
    display: flex;
    justify-content: space-between;
  }

.account-info-helpText{
        opacity:.5;
        margin-bottom: 10px;
      }
  .settings .settings-section .account-info-row .account-info-section {
    flex: 1;
    text-align: center;
    font-size: 20px; }
    .settings .settings-section .account-info-row .account-info-section .account-field {
      display: block;
      font-size: 18px;
    opacity: .5;}

.settings .settings-section .user-list {

  order:1;
  flex-grow: 4;
  min-width: 500px;
  }
  .settings .settings-section .user-list .user-list-header {
    border-radius: 8px 8px 0 0;
    border-bottom: var(--border-gray);
    display: flex;
    color:var(--white); }
    .settings .settings-section .user-list .user-list-header .user-list-header-field {
      width: 175px;
      text-align: center;
      color:var(--dark-gray);}
  .settings .settings-section .user-list .user-list-row {
    border-bottom: var(--border-gray);
    border-radius: 8px;
    display: flex; }

    .settings .settings-section .user-list .user-list-row .user-list-section {
      width:175px;
      text-align: center;
    white-space: normal;}
.helpText-users{

}
.settings-users-contents{
  display: flex;
}

#edit-role{
  font-size: 16px;
  flex:1;
  text-align:  center;
}
#getAccessCode{
  cursor:pointer;
  border: 1px solid var(--dark-gray);
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 14px;
  margin-left: 10px;
}
.notificationsSettings{
  display: flex;
}
.notificationsSettings-value{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notification-info-field{
  border-radius: 6px;
  height:25px;
  font-size:20px;
  border:var(--border-gray);
}
.notificationsSettings-savebuttons{
  display: flex;
  flex-direction: column;
  margin:20px 0 0px 10px;
}
.editNotifications{
  cursor: pointer;
}
.settingsTags-container{
  display: flex;
  flex-wrap: wrap;
  flex:1;
}
.user-list-header-field-tags{
  display: flex;
  color: var(--dark-gray);
  flex:1;
  justify-content: center;
}
