.sentDatePicker-content{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sentDatePicker-header{
  display: flex;
  justify-content: space-between;
}
.sentDatePicker-clear{
  color: var(--fill-green);
  cursor: pointer;
}
