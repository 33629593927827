.headerButton{
  width:40px;
  margin: 2px;
  cursor: pointer;
  border:none;
  background-color: transparent;
  outline: none;
  display: flex;
}
.is-flex-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonIcon{
  width:30px;
  height: 30px;
}


.remind-sendTemplateToModal{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#buttonCreate{
  width:100px;
  color: var(--fill-green);
  font-size: 20px;

}
.input-info-field{
  width:80%;
  height:30px;
  border-radius: 3px;
  border:1px solid var(--dark-gray);
}
#buttonCreateTag{
  width:50px;
  color: var(--fill-green);
  font-size: 12px;

}
#buttonTag{
  width:40px;
  height: 35px;
}
.buttonUpload{
  width:90px;
  color: var(--white);
  height: 40px;
  text-align: center;
  background-color: var(--fill-green);
  border-radius: 3px;
  font-size: 20px;
  border: none;
}
#buttonReUpload{
  width: 45px;
}
#addSubTag-container{
  width: 100%;
}
.buttonUpload{
  text-decoration: none;
  outline:none;
  margin: 0 5px;
  line-height: 40px;
}
#buttonAddSubTag{
  font-size: 12px;
  width: 100%;
  opacity: .6;
}
#buttonAddSubTagTagsPage{
  color: white;
  font-size: 18px;
}

.helpText-CreateTagButton{
  color:black;
}

.document-upload-icon{
  width: 30px;
}
#buttonReUpload{
  width: 45px;
}
.file-dropzone-text#ReUploadButton{
  padding: 20px;
}
.button-modalText{
  text-align: center;
}
.multiTagButton-tagNameList{
  list-style:none;
}
.multiTagButton-container{
  display: flex;
  flex-direction: row;

}
.buttonDocumentsFromTags{
  background-color: transparent;
  outline:none;
  border: none;
  width: 40px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  padding-top:1px;
}
.multiTagButton{
  outline:none;
  margin: 10px 10px 0 10px;
  width: 40%;
  height:40px;
  font-size: 16px;
  border-radius: var(--container-corners)
}
#buttonSendTo{
  width:130px;
  font-size: 14px;
  color: white;
  border-radius: 3px;
  height:30px;
  background-color: var(--fill-green);
  align-self: baseline;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.sendTemplateButton-spacer{
  height: 25px;
}
.input-template-id-field{
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--dark-gray);
  height: 30px;
}
#buttonSendDocumentTo{
  width:130px;
  font-size: 14px;
  color: white;
  border-radius: 3px;
  height:30px;
  line-height: 30px;
  background-color: var(--fill-green);
  align-self: baseline;
  text-align: center;
  justify-content: center;
}
#buttonSendDocumentToIcon{

  width:30px;
  margin-right: 7px;

}
.template-send-info-field{
  border-radius: 8px;
  border: 1px solid gray;
  min-height: 80px;
  max-height: 150px;
  min-width: 200px;
  max-width: 80%;
}
.template-send-info-field-tags{
  border-radius: 8px;
  border: 1px solid gray;
  height: 80px;
  resize: horizontal;
  min-width: 200px;
  max-width: 80%;
}
.buttonCreateTemplate{
  border-radius: 3px;
  background-color: var(--fill-green);
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  height:40px;
  border: none;
}
