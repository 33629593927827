.documents-preview{
  margin: 10px;
  padding: 5px;
  background-color: var(--white);
}
.documents-preview-header{
  height: 40px;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
  color: black;
}
.buttons-docPreview{
  display: flex;
}
.recipePreview-numIngredients{
  text-align: center;
}
.recipePreview-ingredients{
  font-size:14px;
}
.recipePreview-ingredients-container{
  margin:10px 0;
}
.documents-preview{
  min-height: 100vh;
}
.preview-previewer{
  height: 100%;
}
.documents-preview-name{
  font-size: 24px;
  margin-left: 5px;
}
.documents-preview-body{
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.docPreview-tagscontainer{
  display: block;
}
.button-documentView{
  float: right;
  margin-top: 10px;
  opacity: .5;
  cursor: pointer;
  outline:none;
  white-space: nowrap;

}


.button-documentView-header{
  order:1;
  margin-left:10px;
  display: inline-block;
  text-decoration: none;
  color: var(--fill-green);



}
.button-documentView-footer{
  color:black;
  opacity: .6;
  margin-top: 20px;
  align-self: flex-end;
  text-decoration: none;
}
.docPreview-date-created{
  align-self: center;
}
