.document-viewer {
  margin: 20px;
  display: flex;
}


.document-label {
  display: block;
}

.document-viewer-infoField {
  width: 50%;
}

.document-status-sentToContainer {
  display: flex;
  justify-content: space-between;
}

.document-viewer-status {
  color: var(--dark-gray);
  margin-left: 5px;
}

.reminderButton-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.receiverStatus {
  color: var(--dark-gray);
  font-size: 12px;
}

.markOriginalSender {
  color: var(--fill-green);
  font-size: 12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.document-toOrganization-date {
  margin-left: 5px;
}

.document-toOrganization {
  margin-right: 5px;
  word-break: break-all;
  max-width: 200px;
}

.button-ReturnViewer {
  margin: 7px 0px;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--fill-green);
  font-size: 12px;

}

.documentViewer-uneditableSection {
  margin-top: 15px;
}

.documentViewer-uneditableHelpText {
  font-size: 13px;
  opacity: .7;
  margin-left: 5px;
  color: var(--dark-gray);
}

.tab-top-rightButtons {

  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sentTo-tableHeader {
  color: var(--dark-gray);
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  border-bottom: var(--border-gray);
}

.document-viewer-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.previewer {
  font-size: 28px;
  vertical-align: -20%;
  /* Manual put to center in bar. Not ideal. */
}

.document-viewer-fields {
  order: 1;
  flex: 4;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.document-viewer-midRightColumn {
  display: flex;
  order: 2;
  flex-direction: column;
  min-width: 300px;
  max-width: 450px;
  margin: 0 20px;
  background-color: white;
  min-height: 100vh;
  padding: 20px;
}

.document-viewer-rightColumn {
  display: flex;
  order: 3;
  flex-direction: column;
  width: 300px;
  background-color: white;
  padding: 20px;
}

.document-viewer-middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 40%;
  padding: 20px 40px;
}

.document-viewer-fields-header {
  font-size: 24px;
  order: 1;
  margin-bottom: 20px;
}

.document-viewer-rightColumn-header {
  display: flex;
}

.document-viewer-header-text {
  font-size: 24px;
}

.document-viewer-tabActive {
  color: var(--fill-green);
  border-bottom: 2px solid var(--fill-green);
}

.document-viewer-tabInactive {
  color: var(--dark-gray);
}

.document-tags-container .document-tags {
  font-size: 26px;
  height: 97px;
  width: 300px;
  display: inline-block;
  border: 1px solid var(--border-gray);
  border-radius: 12px;
}

.document-viewer-column-header-text {
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logSummary,
.versionHistory {

  box-sizing: border-box;
  text-align: left;
}

.logSummary-contents {
  overflow: auto;
  max-height: 100vh;
}

.viewer-textButtons {
  order: 1;
}

.button-sendDoc {
  float: right;
  color: var(--white);
  border: none;
  background-color: transparent;
  margin: 6px 6px 0 0;
}

.document-viewer-permissions {
  overflow-y: auto
}

.document-viewer-permissions-header {
  display: flex;
  justify-content: space-between;
}

.document-versionHistoryContents {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}

.versionHistory-link {
  color: white;
  text-decoration: none;
  padding-right: 10px;
}