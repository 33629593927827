.pricingPlan-container{
  width: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: 10px 5px;
  height: 300px;
}
.pricingPlan-header{
  font-size: 13px;
  padding: 12px;
  font-weight: 600px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  background-color: #fcfdfe;
  border-bottom: 1px solid #f1f2f3;
  font-weight:600px;
  width: 100%;
}
.pricing-table-title{
  font-size: 30px;
  text-align: center;
  margin: auto;
}
.pricing-table-categoryHeaders{
  text-align: center;
  font-size: 24px;
  margin: 10px auto 0 auto;
  border-bottom: 1px solid var(--fill-green);
}
.pricing-table, .pricing-table-cell{

  margin: 0;
  border-spacing:0;
  padding: 3px 7px;
  text-align: center;
}
.pricing-table-cell{
    border: var(--border-gray);
}
.pricing-table-header{
  width: 150px;
  padding: 3px;
}
.pricingPlan-details{
  margin-top: 10px;
  padding: 0 10px;
}
.pricingPlan-terms{
  color: var(--fill-green);
  display: flex;
  align-items: flex-start;
  font-size: 40px;
  line-height: 40px;
  margin-top: 20px;
}
.pricingPlan-USD{
  display: inline-block;
  font-size: 12px;
  margin: 0 5px;
}
.pricingPlan-amount{
    font-size: 40px;
    line-height: 40px;
  font-weight: 1000;
}
.dollarSign{
  align-self: flex-start;
  margin: 4px 2px;
  font-family: sans-serif;
  font-size: 12px;
  display: inline-block;
}
.pricingPlan-iconBullet{
  height: 10px;
  margin-right: 5px;
}
.pricingPlan-features{
  font-size: 14px;
  margin: 10px 5px;
  color: var(--dark-gray);
}
.pricingPage-header{
  font-size: 32px;
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10px 40px;
  max-width: 880px;
}
.pricingPage-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10%;
}
.pricing-planContainer{
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  align-items: center;
  max-width:950px;
}
.pricing-signup{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.pricingPlan-review{
  display: flex;
  flex-direction: column;
}
.pricingPlan-author{
  align-self: flex-end;
}
.pricingPlan-customerQuote{
  padding: 10px 30px;
}
.pricingPlan-section{
  max-width: 900px;
  padding: 20px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}
