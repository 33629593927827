.faq-container{
  padding: 20px 100px 20px 80px;
}
.question{
  margin-bottom: 0px;
}
.faq-quickNav{
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding:20px;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  flex: 0;
  height:350px;
}
.faq-section-header{
  margin-top: 60px;
}
.faq-quickNav-header{
  font-size: 24px;
}
.faq-quickNav-link{
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.faq-container-contents{
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0px 0 0 260px;
  padding: 40px;
}
