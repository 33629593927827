#buttonDeleteTag{
  color: var(--fill-green);

  width: 70px;
  white-space: nowrap;
}
.buttonDeleteTag{
  display: flex;
  align-items: center;
  justify-content: center;
}
