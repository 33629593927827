.passwordreset-container{
  padding: 30px;
  background-color: white;
}
#email-reset{
  width:350px;
  display: block;

}
.reset-header{
  font-size: 30px;
}
.enterreset-container{
  padding:50px;
  width:50vh;
  background-color: white;
}
