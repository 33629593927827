.recipe-viewer {
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.recipe-main {
  background-color: white;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.recipe-cost-selector {
  display: flex;
  margin-left: 10px;
}

.recipeViewer-changeIngredientButtons {
  display: flex;
  justify-content: space-between;
}

.recipe-unit-selector {
  margin: 0 5px;
}

.recipeInfo {
  display: flex;
  flex-direction: column;
}

.relatedDocuments-buttonContainer {
  display: flex;
}

.recipeInfo-main {
  display: flex;
  justify-content: space-between;
}

.recipeInfo-right {
  display: flex;
  align-items: baseline;
  flex-direction: row-reverse;
}

.recipe-add-ingredient {
  color: var(--fill-green);
  cursor: pointer;
}

.recipe-viewer-right-box {
  display: flex;
  order: 2;
  flex-direction: column;
  min-width: 300px;
  max-width: 450px;
  margin: 0 20px;
  background-color: white;
  min-height: 40vh;
  padding: 20px;
}

.recipe-viewer-right-top-box {
  margin-bottom: 20px;
}

.recipe-title {
  font-size: 26px;
  font-weight: 500;

}

.recipe-ingredient-container {
  margin-top: 40px;
}

.recipeLock {
  width: 30px;
  height: 30px;
  color: var(--fill-green);
}

.recipe-totalWeight {
  display: inline;
}

.recipe-totalWeightComplete {
  color: var(--fill-green);
}

.recipeInfo-rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
}

.recipeInfo-right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.recipe-ingredient-title-section {
  display: flex;
  flex-direction: row;
}

.recipe-viewer-right-margin {
  margin: 0 20px;
}

#bom-send {
  display: flex;
  flex-direction: column;
}

.recipe-ingredient-title {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: baseline;
  line-height: 24px;
}

.recipe-ingredient-title-text {
  font-size: 24px;
}

.button-ReturnViewer {
  margin: 7px 0px;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--fill-green);
  font-size: 12px;
}