.SelectFromExistingButton{
  color: var(--dark-gray);
  border-radius: 10px;
  border: 1px dashed var(--dark-gray);
  background-color: var(--row-fill-gray);
  width: 250px;
  height: 100px;
  padding: 20px;
  cursor: pointer;
}
.selectedSignatureList{
  font-size: 14px;
}
.reUpload-file-dropzone {
  border: 1px dashed gray;
  height: 50px;
  width:300px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
background-color: var(--row-fill-gray)}

#ReUploadButton{
  height:150px;
  font-size: 16px;
  line-height: 25px;

}
.reUpload-requiresSignatures-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
