body {
  margin: 0;
  padding: 0;
  font-family: 'Catamaran', sans-serif;
  display: flex;
}
#root{
  flex-grow: 1;
}

:root{
  --border-green:3px solid #77BA43;
  --fill-green:rgb(36,164,81);
  --border-gray:1px solid #EFEFEF;
  --row-fill-gray:#F4F4F4;
  --dark-gray:#939393;
  --light-gray:rgb(245,245,245);
  --white:white;
  --container-corners:14px;
  --container-corners-left:0 14px 14px 14px;
}
.pointer{
  cursor: pointer;
}
.toast-container{
  z-index: 9999;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.is-spaced-between{
  display: flex;
  justify-content: space-between;
}
.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  -webkit-animation: lds-dual-ring 1.2s linear infinite;
          animation: lds-dual-ring 1.2s linear infinite;
}
@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid rgb(36,164,81);
  border: 6px solid var(--fill-green);
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(36,164,81) transparent transparent transparent;
  border-color: var(--fill-green) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loaderContainer{
  align-self: center;
  margin: 0 auto;
}
.App {
  text-align: center; }
input, textarea{
  font-family: 'Rubik','Catamaran', sans-serif;
}
.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px; }
.boxShadow{
  box-shadow: 0 2px 2px 0 #DEDEDE;
}
.is-spaced-between{
  display: flex;
  justify-content: space-between;
}
.smallHelpText{
  color: #939393;
  color: var(--dark-gray);
  font-size: 12px;
}
.is-clickable{
  cursor: pointer;
}
.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white; }

.App-title {
  font-size: 1.5em; }

.App-intro {
  font-size: large; }

.content {
  display: flex;
  background-color: #F4F4F4;
  background-color: var(--row-fill-gray);
  min-height: 100vh;
  flex-direction: column;
  position: relative; }

.content-body {
  flex: 1 1;
padding: 20px;}

.section-header {

  text-align: center;
  color: white;
  background-color: rgb(36,164,81);
  background-color: var(--fill-green);
  font-size: 36px;
  line-height: 74px;
  height: 74px;
  border-radius: 8px 8px 0 0; }

.primary{
  background-color: rgb(36,164,81);
  background-color: var(--fill-green);
  color: white;
}
.container{
    border: 3px solid #77BA43;
    border: var(--border-green);
    border-radius: 14px;
    border-radius: var(--container-corners);
}
.container-left{
    border: 3px solid #77BA43;
    border: var(--border-green);
    border-radius: 0 14px 14px 14px;
    border-radius: var(--container-corners-left);
}
.noselect{
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */ /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.wordbreak{
  word-wrap: break-word;
  word-break: break-word;
}
.table-row{
  border: 1px solid #EFEFEF;
  border: var(--border-gray);
}
.table-row:nth-child(even){
  background-color: #F4F4F4;
  background-color: var(--row-fill-gray);
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.SingleDatePickerInput__withBorder{
  width: 100%;
}
.SingleDatePicker{
  width: 100%;
}
.datePickerContainer{
  box-sizing: border-box;
  width: 180px;
}

.header {
  background-color: white;
  color: black;
  height: 40px;
box-shadow: 0 2px 4px 0 #DEDEDE;
display: flex;
flex-wrap: nowrap;
justify-content: space-between;}
  .header a {
    text-decoration: none;
    color: black;
  height: 100%;}
  .header .header-link {
    font-size: 18px;
    line-height: 40px;
    padding: 0 15px;
    height: 40px;
    box-sizing: border-box;
  }
  .header .header-link:hover {
    border-bottom: 2px solid var(--fill-green)}
  .header .header-left {
    display: flex;
    position: absolute;
    left: 0;
    margin: 0 15px;
    white-space: nowrap; }
  .header .header-right {
    display: flex;
    position: absolute;
    right: 0;
    margin: 0 15px; }
    .header-logo-unauth{
      height:40px;
      margin: 10px auto;
    }
  .header .header-logo {
    height: 26px;
    -webkit-transform: translateY(5px);
            transform: translateY(5px); }

.current {
  border-bottom: 2px solid var(--fill-green)}

.header-unauth {
  height: 65px;
  background-color: var(--white);
  border: 1px solid #979797;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-gray);
  padding: 0 20px;
  vertical-align: middle;
}
.header-email{
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.introJs-header-notifications{
  position:relative;
}
.unreadNotifications{
  display: inline;
  font-size: 16px;
  border-radius: 5px;
  margin-left: 5px;
  height: 15px;
  padding: 2px 10px;
  background-color: var(--fill-green);
  color: white;
}
#introJs-header-permissions{
  max-width: 150px;

}
.header-login{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.header-login-itemContainer{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5px;
}
.header-input-text{
  border-radius: 2px;
  border: var(--border-gray);
}
.header-register-text{
  color:var(--dark-gray);
  cursor: pointer;
  font-size: 10px;
}
.header-input-label{
  font-size: 12px;
}
.header-errorText{
  font-size: 12px;
  background-color: white;

}
.header-unauth-left{
  display: flex;

}
#header-signup-button{
  padding: 3px 7px 4px 7px;
  width: 100%;
  background-color: var(--fill-green);
  margin: auto 0;
  color: var(--white);
  border-radius: 4px;
  border: none;
  white-space: nowrap;
}
#header-login-button{
  padding: 3px 7px 4px 7px;
  width: 100%;
  border: 1px solid var(--fill-green);
  margin: auto 10px;
  color: var(--fill-green);
  border-radius: 4px;
  white-space: nowrap;
}
@media screen and (max-width:950px){
  .header .header-right{
    display: none;
  }

}
#header-features-button{
  color: var(--dark-gray);
  margin: auto 10px;
}

.PermissionsNavigator-container{
  display: inline-flex;
  flex-direction: column;
}
.PermissionsNavigator-submenu{
  display: flex;
  flex-direction: column;
  padding: 0px 5px;
  font-size: 14px;
  line-height: 24px;
  z-index: 100;
  background-color: white;

}
a.templates-submenu-links{
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.dropdownTriangle{
  display: inline-block;
  margin-left: 5px;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;

  border-top:8px solid var(--fill-green);
}

.footer-unauth {
  height: 52px;
  background-color: #EFEFEF; }
  .footer-unauth .footer-links {
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    line-height: 52px;
    font-size: 14px;
    color: #BCB9BD; }
  .footer-unauth a {
    width: 250px;
    text-align: center;
    text-decoration: none;
    color: #BCB9BD; }
    .footer-unauth a:hover {
      cursor: pointer; }
  .footer-unauth .copyright {
    right: 5px;
    position: absolute; }

.footer-auth {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 30px;
  background-color: #EFEFEF;}

  .footer-auth .footer-links {
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    font-size: 14px;
    color: #BCB9BD;
  white-space: nowrap;}
  .footer-auth a {
    width: 250px;
    text-align: center;
    text-decoration: none;
    color: #BCB9BD; }
    .footer-auth a:hover {
      cursor: pointer; }
  .footer-auth .copyright {
    right: 5px;
    position: absolute; }
.footer-buffer{
  width: 100%;
  height:30px;
}

.logs{
  background-color: white;
  margin: 20px;
  padding: 20px 30px;
}
.logs-container{
  display: flex;
}
.logsheader#logs-header {
  font-size: 26px;
  height:50px;
  white-space: nowrap;
}
.log-info-field{
  border-radius: 5px;
  border: var(--border-gray);
  width: 100%;
  resize: vertical;
}
.log-row {
    padding: 2px 10px;
    min-height: 40px;

    font-size: 14px;
    font-weight: 300;
    color: #000000;
    display: flex;
  align-items: center;}
.log-row-container{
  border-bottom: var(--border-gray);
}
.log-text {
  margin-left: 10px;
  display: inline-block;
  line-height: 16px;
  width: 600px; }
.logsTableHeader{
  border-bottom: var(--border-gray);
  color:var(--dark-gray);
}
.logsTableHeader-action{
  width:645px;
  margin-left: 10px;
}
.logsTableHeader-timestamp{
  width:200px;
  text-align: center;
}
.logsTableHeader-user{
  text-align: center;
  width:150px;
}
.log-user{
  width:150px;
}
.log-icon {
        height: 35px;
      width: 35px;}
.log-timestamp{
  width: 200px;
  text-align: center;
}
.log-user{
  width:150px;
  text-align: center;
}
.log-notes{
  background-color: var(--row-fill-gray);
  width: 100%;
  margin-bottom: 5px;
  padding: 0 5px;
}
.log-messageContainer{flex:1 1;}
.logSummary{
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
}
.summaryLogs-seeMore{
  text-decoration: none;
  color: black;
  opacity: .6;
  align-self: center;
  outline:none;
  line-height: 40px;
}
.logsTableHeader{
  display: flex;
}

.ShortLogsRow-container{
  padding: 0 10px;
  margin: 6px 5px 0px 6px;;
  min-height: 40px;
  border-bottom: var(--border-gray);
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.shortLogs-description{
   flex:1 1;
}
.shortLogs-timestamp{
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.shortLogs-messageContainer{
  flex-wrap: wrap;
  display: flex;
  flex:1 1;
  max-width: 30%;
}
.log-text-info{
  color:var(--dark-gray);
}
.log-text-noteButton{
  color:var(--fill-green);
  cursor: pointer;
}
.log-circle{
  height:8px;
  width:8px;
  border-radius: 100px;
  background-color: var(--fill-green);

}
.log-notes-field{
  width: 100%;
  resize: vertical;
  margin-bottom: 5px;
}
.log-decoration{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0 0 7px;
  box-sizing: border-box;
}
.log-line{
  border-left: var(--border-gray);
  flex-grow: 1;
  width:1px;
  box-sizing: border-box;
}
.log-line-short{
  border-left: var(--border-gray);
  height: 12px;
  width:1px;
  box-sizing: border-box;
}

.summaryLogsRow-container{
  display: flex;
}

.notificationsheader{
  font-size: 26px;
  height:40px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  white-space: nowrap;
}
.notifications-body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-allUnsignedSignatures{
  font-size: 12px;
  color: var(--fill-green);
  width: 75px;
  white-space: normal;
  text-align: center;
  height: 50px;
  cursor:pointer;
  display: flex;
  align-self: flex-start;
}
.summaryNotifications-seeMore{
  text-decoration: none;
  color: black;
  opacity: .6;
  align-self: center;
  outline:none;
  line-height: 40px;
}
.notifications-rightContainer{
  max-width: 600px;
}
.button-markAllRead{
  font-size: 16px;
  margin-left: 20px;
  cursor: pointer;
}
.notifs-docs-tableheader-expires{
  margin: 0 15px 0 120px;
}
.notifications-container{
  padding: 20px;
  margin: 20px;
  background-color: white;

}
.notifications-left{
  flex:1 1;
}
.notifications-masterContainer{
  display: flex;
  flex-wrap: none;
}
.notifications-permissions{
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pageButton {
  margin: 0 5px;
  display: inline-block;
  color: black;
  border: none;
  width: 30px;
  height: 30px;
  outline: none;
  background-color: white;}
.pageButton:hover{
  cursor: pointer;
}
.pageButtons {
  margin: 10px auto;
  text-align: center; }


#selectedPage{
  background-color: var(--fill-green);
  color: var(--white);
}


.document-row{
	display: flex;
	min-height: 35px;

	padding: 3px 10px ;
	border-bottom: var(--border-gray);
	align-items: center;
	justify-content: space-between;

}
.document-row-short{
	display: flex;
	min-height: 30px;
	border-radius: var(--container-corners);
	padding: 3px 15px 5px 10px ;
	border:var(--border-gray);
	justify-content: space-between;
}
#document-row-active{

	border:2px solid var(--fill-green);
	border-radius: 5px;

}
.expiredDot{
	background-color: #E65531;
	width: 6px;
  border-radius: 50px;
  height: 6px;
	margin-right: 3px;
	min-width: 6px;
	max-width: 6px;
	min-height: 6px;
	max-height: 6px;
}
.document-row-info{
	display: flex;
	flex-direction: column;
	width: 300px;
}
.document-row-name{
	order: 1;
	font-size:14px;
}
.short-document-row-name{
	order: 1;
	font-size:14px;
	line-height: 18px;
}
.document-row-id{
	order:2;
	font-size: 12px;
	color: var(--dark-gray);
}
.document-tag-container{
	line-height: 16px;
	width: 300px;
}
.document-row-category{
	font-size: 24px;
	line-height: 24px;
}
.short-document-row-category{
	font-size: 14px;
	line-height: 18px;
}

.document-expiration-date{
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 100px;
}
.short-document-expiration-label{
	font-size: 14px;
}
.short-document-row-right{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.button-document-row-infoViewer{
	font-size: 12px;
	width: 35px;
	align-self: center;
	cursor: pointer;
	line-height: 14px;
}
.document-short-row-left{
	display: flex;
	flex-direction: column;
}

.tagLabel-container{
  border:var(--border-gray);
  background-color: var(--light-gray);
  font-size: 12px;
  width: -webkit-min-content;
  width: min-content;
  padding: 0.5px 5px;
  border-radius:5px;
  display: inline-block;
  margin:2px 3px;
  max-width: 100%;
  word-break: normal;
  white-space:nowrap;
  word-wrap: break-word;
}

.svg-icon {
  fill: tomato; }

.notification-item{
  border-bottom: var(--border-gray);

  display: flex;
  padding: 10px ;
  width:100%;
  cursor: pointer;
  box-sizing: border-box;
}
.notification-item-summary{
    padding: 12px 10px;
    cursor: pointer;
    border-bottom: var(--border-gray);
    display: flex;
    align-items: center;
    width:100%;
    box-sizing: border-box;
    font-size: 14px;
}
.notification-row-header{
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}
.notification-icon{
  display: inline-block;
  height:45px;
  fill:var(--fill-green);
  vertical-align: middle;
  text-align: center;

}
.notification-row-date{
  color: var(--dark-gray);
  margin: 0 4px;
  font-size: 12px;
}
.notification-row-name{
  font-weight: bold;
}
.notification-dateIcon{
  display: flex;
  margin-right: 5px;
  white-space: nowrap;
  align-items: center;
  flex-direction: column;
}
.notification-description{
  flex:2 1;
  word-break: break-word;
}
.helpText-notifications{
  font-size: 14px;
  line-height: 14px;
}

.notification-read{
  border-radius: 5px;
  height: 10px;
  width: 10px;
  background-color:var(--dark-gray);
}
.notification-unread{
  border-radius: 5px;
  height: 10px;
  width: 10px;
  background-color: var(--fill-green);
}

.button-remindDocumentComponent{
  color: var(--fill-green);
  cursor: pointer;
}

.externalDocs-container{
background-color: white;
padding: 20px;
margin: 20px;
overflow-y: auto;
}
.external-sharedWith{

}
.externalDocs-content{
  max-height: 500px;
  overflow-y: auto;
  padding-right: 80px;
}
.pendingExternalRow-container{
  display: flex;
  align-items: baseline;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom:var(--border-gray);
}
.externalDoc-name{
  cursor: pointer;
}
.externalDocs-filters{
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}
.externalDocs-selectUser{
  margin-left: 5px;
  height: 20px;
  background-color: white;
  border: 1px solid var(--fill-green);

}

.sentDatePicker-content{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.sentDatePicker-header{
  display: flex;
  justify-content: space-between;
}
.sentDatePicker-clear{
  color: var(--fill-green);
  cursor: pointer;
}

.home {
  display: flex;
  background-color: var(--row-fill-gray);
  padding:20px 40px;}

.home-left{
  flex: 1 1;
  min-height: 91vh;
  width: 30%;
  min-width: 300px;
  background-color: white;
  overflow-y: auto;
}
.home-docs-tableheader-name{
  width: 310px;
}
.tags-docs-tableheader-ingredients{
  width:100px;
}
.home-left-changelog{
  padding: 5px 15px;
  max-height: 90vh;
  background-color: white;
}
.homeButtons{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goMobileButton{
  background-color: var(--fill-green);
  color: white;
  text-align: center;
  border-radius:3px;
  margin:5px;
  font-size: 20px;
  height: 40px;
  width: 150px;
  cursor: pointer;
  line-height: 42px;
}
.home-middle{
  order:2;
  flex: 1 1;
  padding: 15px;
  margin: 0 20px;
  min-width: 300px;
  background-color: white;
}
.home-right{
  order:3;
  width:30%;
  min-width: 300px;
  background-color: white;
}
.welcomeMessage{

}
.home-container{
  height:30vh;
  overflow-y: auto;
}
.tutorialButton{
  width: 150px;
  background-color: var(--fill-green);
  color: white;
  height: 40px;
  font-size: 20px;
  border-radius: 6px;
  cursor: pointer;
  line-height: 42px;
  text-align: center;
  margin: 0 auto;

}
.tutorialOptions{
  background-color: white;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
.tutorialOption{
  cursor: pointer;
  color: var(--fill-green);
}
.tutorial2Container{
margin-top: 10px}

.pending-button{
  background-color: transparent;
  border:none;
  outline:none;
  fill:white;
  width:60px;
  cursor: pointer;

}
.pending-buttonContainer{
  order:4;
  display: flex;
  align-items:baseline;
  justify-content: flex-end;
}

.loadingButtonContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 10px;
}

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #fff;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 2px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 2px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 14px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(6px, 0);
              transform: translate(6px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(6px, 0);
              transform: translate(6px, 0);
    }
  }

.landing-paragraphText{
  font-size: 20px;
}
.landing-headerText{
  font-size: 32px;
}
.landing-sectionContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
  min-width: 620px;
}
.customerReview-header{
  font-size: 32px;
}
.customerReview{
  padding: 20px;
}
.book-demo-link{
  text-decoration: none;
  color: white;
  background-color: var(--fill-green);
  padding: 2px 8px;
  border-radius: 7px;
  font-size: 24px;
  width:-webkit-min-content;
  width:min-content;
  white-space: nowrap;
  margin: 0 auto;
  cursor: pointer;
}
.landing-sectionText{
  display: flex;
  flex-direction: column;
  text-align: left;

}
.tagsBannerText{
  position: absolute;
  width: 40%;
  left: 3vw;
  top: 10vw;
  text-align: left;
}
.foldersBannerText{
  width: 40%;
  right: 3vw;
  top:10vw;
  position: absolute;
  text-align: left;
}
.landing-secondsignup{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bannerTitle{
  font-size: 32px;
  font-size: 3vw;
  color: white;
}
.bannerText{
  font-size: 16px;
  font-size: 1.5vw;
  color:white;
}
.landing-section{
  background-color: white;
  padding: 30px 10%;
  margin: 20px 5%;
  min-width: 520px;
}
.landing-sectionTextItem{
  margin-bottom: 20px;
}
.landing-sectionTitle{
  font-size: 36px;
  margin-bottom: 10px;
}
.pageContainer{
  max-width: 100%;
  text-align: center;
}

.imgGuyConfused{
    width:50%;
    margin-right: 20px;
}
.profilePic{
  border-radius: 10000px;
  width: 100%;
  height: auto;
}

.teamItem{
  max-width: 27%;
}
.landing-imageButton{
  background-color: white;
  border-radius: 10px;
  color:var(--fill-green);
  font-size: 20px;
  font-size: 1.5vw;
  height: 4vw;
  margin-top: 1vw;
  padding: 0 13px;
  border: transparent;
  line-height: .75vw;
  cursor: pointer;
}
.teamSection{
  display: flex;
  padding: 50px;
  justify-content: space-between;
}
.landing-header{
  min-width: 660px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 5%;
  position: relative;
}
.landing-banner{
  width: 100%;
}
.landing-form-button{
  font-size: 24px;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;
}
.landing-input-text{
  margin: 0 auto;
  border-radius: 8px;
  font-size: 20px;
  border: var(--border-gray);
  width: 40%;
  height: 30px;
  display: block;
}
.landing-banner-text{
  margin:0 10%;
}
@media screen and (max-width:800px){
  .landing-sectionContainer{
    flex-direction: column;
  }
  .imgGuyConfused{
      width:75%;}
  .bannerTitle{
        font-size: 24px;
      }
  .bannerText{
        font-size: 12px;
      }
  .tagsBannerText{
        left: 30px;
        top: 25%;
      }
.foldersBannerText{
        right: 30px;
        top: 25%;
      }
      .landing-imageButton{
        border-radius: 10px;
        color:var(--fill-green);
        font-size: 14px;
        height: 35px;
        padding: 0 13px;
        border: transparent;
        cursor: pointer;
      }
}

.interactiveDemoContainer{
  width: 620px;
  padding: 20px;
  height:350px;
  position: relative;
}
.interactiveDemoContainer-container{
  background-color: white;
  margin: 20px 5%;
  min-width: 620px;
  padding: 0 0px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.opaqueDemoObject{
  opacity: 1;
}
.interactiveDemo-sectionTitle{
  font-size: 32px;
  padding: 12px;
  font-weight: 600px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  background-color: #fafafa;
  color: #151515;
  border-bottom: 1px solid #d3d3d3;
  font-weight:600px;
  width: 100%;

}
.interactiveDemo-useCases{
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin:0  5% ;
  align-items: center;
  justify-content: center;

}
.interactiveDemo-useCase-container{
  text-align: center;
  width: 30%;
  margin: 40px auto 0 auto;
  background-color: white;
  padding-bottom:5px;
  box-sizing: border-box;
  min-width: 300px;
}
.interactiveDemo-useCase-header{
  font-size: 24px;
  background-color: #fafafa;
  color: #151515;
  padding: 0 10px;
  border-bottom: 1px solid #d3d3d3;
}
.interactiveDemo-useCase-text{
  padding: 25px;
}
.transparentDemoObject{
  opacity: .4;
}
.demoDoc{
  position: relative;
}
.demoTag{
  cursor: pointer;
}
.interactiveDemo-topTags, .interactiveDemo-bottomTags{
  position: relative;
  display: flex;
  height: 100px;
}
.interactiveDemo-text{
  font-size: 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.popupClickTag{
  background-color: var(--fill-green);
  height: 26px;
  color:white;
  margin: 0 auto;
  border-radius: 5px;
  padding: 3px 5px;
  z-index: 100;
}
.interactiveDemo-text-instructions{
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.interactiveDemo-docs{
  display: flex;
  justify-content: space-between;
}
.demoRedTag{
  left: 0px;
  z-index: 10;
  position: absolute;
}
.demoGreenTag{
  position: absolute;
  right: 40px;
}
.demoPlantsTag{
  position: absolute;
  right: 30px;
}
.demoFruitTag{
  position: absolute;
  left: 20px;
}
.landing-goFeaturesButton{
  background-color: var(--fill-green);
  color:white;
  border-radius: 4px;
  width: 125px;
  margin: 0 auto;
  cursor: pointer;
}

.features-sectionContainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 20px 30px;
  align-items: center;

}
.features-pageContents{
  margin: 0 5%;
}
.public-faq-container{
  background-color: white;
  margin:30px 5%;
  padding: 30px 10%;
}
.features-largeGraphic-header{
  font-size: 32px;
}
.features-largeGraphic-textContainer{
  margin: 0 auto;
}
.features-documentFeatures{
  max-width: 60%;
  margin: 0 auto;
}
.features-tagsSection-header{
  margin: 30px 0 0 0;
  font-size: 32px;
}
.features-documentFeaturesGraphic{
  display: flex;
  align-items: center;
  margin: 20px 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.features-largeGraphic-text{
  padding: 5px 30px;
  font-size: 1rem;
  min-width: 300px;
  max-width: 400px;
}
.features-sectionTitle{
  background-color: white;
  font-size: 32px;
  padding-top: 30px;
}
.features-pageContents{
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
.features-banner-container{
  margin: 0 5%;
  background-color: white;
}
.features-banner-image{
  width: 100%;
}
.features-wantMore{
  padding: 0 30px;
}
.features-signup-button{
  text-decoration: none;
  padding: 3px 7px 4px 7px;
  width: 80px;
  background-color: var(--fill-green);
  margin: 10px auto;
  color: var(--white);
  border-radius: 4px;
  border: none;
  font-size: 20px;
  white-space: nowrap;
}
.features-section{
  background-color: white;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  max-width: 450px;
  min-width: 300px;
  padding: 0 10px;
  margin: 0 auto;
}
.features-sectionTextItem{
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.features-headerText{
  font-size: 20px;
}
.features-paragraphText{
  color:var(--dark-gray);
}
.features-Icon{
  width: 100px;
}

.featuresList-featureTitle{
  font-size: 20px;
  border-bottom: 1px solid var(--fill-green);
  margin-top: 20px;
}
.featuresList-title{
  font-size: 30px;
}
.featuresList-featureText{
  text-align: left;
}
.featuresList-container{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: white;
  padding: 20px 30px;
  margin: 40px 5%;
}

.pricingPlan-container{
  width: 280px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  margin: 10px 5px;
  height: 300px;
}
.pricingPlan-header{
  font-size: 13px;
  padding: 12px;
  font-weight: 600px;
  box-sizing: border-box;
  text-align: center;
  text-transform: uppercase;
  background-color: #fcfdfe;
  border-bottom: 1px solid #f1f2f3;
  font-weight:600px;
  width: 100%;
}
.pricing-table-title{
  font-size: 30px;
  text-align: center;
  margin: auto;
}
.pricing-table-categoryHeaders{
  text-align: center;
  font-size: 24px;
  margin: 10px auto 0 auto;
  border-bottom: 1px solid var(--fill-green);
}
.pricing-table, .pricing-table-cell{

  margin: 0;
  border-spacing:0;
  padding: 3px 7px;
  text-align: center;
}
.pricing-table-cell{
    border: var(--border-gray);
}
.pricing-table-header{
  width: 150px;
  padding: 3px;
}
.pricingPlan-details{
  margin-top: 10px;
  padding: 0 10px;
}
.pricingPlan-terms{
  color: var(--fill-green);
  display: flex;
  align-items: flex-start;
  font-size: 40px;
  line-height: 40px;
  margin-top: 20px;
}
.pricingPlan-USD{
  display: inline-block;
  font-size: 12px;
  margin: 0 5px;
}
.pricingPlan-amount{
    font-size: 40px;
    line-height: 40px;
  font-weight: 1000;
}
.dollarSign{
  align-self: flex-start;
  margin: 4px 2px;
  font-family: sans-serif;
  font-size: 12px;
  display: inline-block;
}
.pricingPlan-iconBullet{
  height: 10px;
  margin-right: 5px;
}
.pricingPlan-features{
  font-size: 14px;
  margin: 10px 5px;
  color: var(--dark-gray);
}
.pricingPage-header{
  font-size: 32px;
  background-color: white;
  width: 100%;
  text-align: center;
  padding: 10px 40px;
  max-width: 880px;
}
.pricingPage-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10%;
}
.pricing-planContainer{
  width: 100%;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
  align-items: center;
  max-width:950px;
}
.pricing-signup{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.pricingPlan-review{
  display: flex;
  flex-direction: column;
}
.pricingPlan-author{
  align-self: flex-end;
}
.pricingPlan-customerQuote{
  padding: 10px 30px;
}
.pricingPlan-section{
  max-width: 900px;
  padding: 20px 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  flex-direction: column;
}

.errorBoundaryContainer{
  margin: 100px auto;
  width: 500px;
  background-color: var(--white);
  border-radius: 5px;
  text-align:center;
  padding: 40px;

}

.form-button {
  border: var(--border-gray);
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 #DEDEDE;
  height: 67px;
  width: 100%;
  font-size: 30px;
  line-height: 67px;
  text-align: center; }
  .form-button:hover {
    cursor: pointer; }
.signup-emailText{
  margin: auto;
  text-align: center;
}
.primary {
  margin: 24px 0; }

.login-logo{
  width:60%;
  align-self: center;
}
.secondary {
  background-color: var(--secondary-button-gray);
  color: #4A4A4A; }

.authform-body {
  padding: 40px 20px 30px 20px;
  background-color: white;
  width: 366px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;}
  .authform-body a {
    text-decoration: none; }
  .authform-body .register-text {
    text-align: center;
    color: #4A4A4A;
    font-size: 20px; }
  .authform-body .signup-header {
    font-size: 39px;
    text-align: center;
    margin: 36px 0 36px 0; }
  .authform-body .signup-hint {
    align-self: flex-end;
    font-size: 14px;
    color: #9B9B9B; }

.label-group {
  display: flex;
  justify-content: space-between; }

.input-text {
  border-radius: 8px;
  border: var(--border-gray);
  height: 40px;
  font-size: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px; }

.input-label {
  display: inline-block;
  font-size: 20px;
  margin-top: 17px; }

.authform-large {
  width: 487px;
  background-color: white;
  margin: 20px auto 40px auto;
  padding: 20px;
  text-align: center; }
  .authform-large a {
    text-decoration: none; }
  .authform-large .access-code-input {
    height: 54px;
    font-size: 40px; }

.create-org {
  width: 430px;
  background-color: #F7F5F5;
  color: #404040;
  margin-bottom: 10px;}
.organization-create-or-container{
  margin: 10px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.organization-create-or{
  margin: 0 auto;
}
.organization-create-line{
  border-top: 1px solid gray;
  opacity:.4;
  width: 40%;
}
.create-org-header {
  font-size: 32px;
margin-top: 10px;}

.join-org-header {
  font-size: 26px;
margin-top:10px;}
.password-reset{
  text-align: center;
}
.join-org {
  text-align: center; }
  .join-org .join-org-subheader {
    font-size: 26px;
    margin-top: 10px; }
  .join-org .input-label {
    font-size: 20px; }

.form-separator-half {
  display: inline-block; }

.helpText-confirmation{
  vertical-align: middle;
  text-align: center;
  padding: 50px;
  width:400px;
  margin: auto;
  background-color: white;
}

.settings
{
  margin: 30px;
}
.settings .settings-section .settings-heading {
  font-size: 24px;
  line-height: 20px;
}

#deactivatedUser{
  color:gray;
}
.individualNotificationSettings-title{
  border-bottom: var(--border-gray);
  font-size: 20px;
  margin-top: 15px;
}
.settings .settings-section .settings-section-divider {
  border: 1px solid var(--fill-green); }
.settings-section{
  background-color: white;
  padding: 40px 40px 20px 40px;
  margin-bottom: 20px;
}
.password-reset-settings{
  color:black;
  text-decoration: none;

}
.deactivatedUserContainer{
  display: flex;
  flex-direction: column;

}
.showDeactivatedUserButton{
  cursor: pointer;
  color: var(--fill-green);

}
.deactivatedUsersList-Container{
  width: 600px;
  color:var(--dark-gray);
}
.user-list-row{
  display: flex;
  justify-content: space-between;
}
.inviteUserButton{
  background-color: var(--fill-green);
  border-radius: 3px;
  padding: 4px 6px;
  cursor: pointer;
  color:white;
  border:none;
  font-size: 14px;
  margin-right: 10px;
}
.organizationButtons{
  display: flex;
}
.settings .settings-section .account-info-row {

  width: 650px;
  display: flex;
  flex-direction: column;
  }
.account-info-row-first,.account-info-row-second {
    display: flex;
    justify-content: space-between;
  }

.account-info-helpText{
        opacity:.5;
        margin-bottom: 10px;
      }
  .settings .settings-section .account-info-row .account-info-section {
    flex: 1 1;
    text-align: center;
    font-size: 20px; }
    .settings .settings-section .account-info-row .account-info-section .account-field {
      display: block;
      font-size: 18px;
    opacity: .5;}

.settings .settings-section .user-list {

  order:1;
  flex-grow: 4;
  min-width: 500px;
  }
  .settings .settings-section .user-list .user-list-header {
    border-radius: 8px 8px 0 0;
    border-bottom: var(--border-gray);
    display: flex;
    color:var(--white); }
    .settings .settings-section .user-list .user-list-header .user-list-header-field {
      width: 175px;
      text-align: center;
      color:var(--dark-gray);}
  .settings .settings-section .user-list .user-list-row {
    border-bottom: var(--border-gray);
    border-radius: 8px;
    display: flex; }

    .settings .settings-section .user-list .user-list-row .user-list-section {
      width:175px;
      text-align: center;
    white-space: normal;}
.helpText-users{

}
.settings-users-contents{
  display: flex;
}

#edit-role{
  font-size: 16px;
  flex:1 1;
  text-align:  center;
}
#getAccessCode{
  cursor:pointer;
  border: 1px solid var(--dark-gray);
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 14px;
  margin-left: 10px;
}
.notificationsSettings{
  display: flex;
}
.notificationsSettings-value{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notification-info-field{
  border-radius: 6px;
  height:25px;
  font-size:20px;
  border:var(--border-gray);
}
.notificationsSettings-savebuttons{
  display: flex;
  flex-direction: column;
  margin:20px 0 0px 10px;
}
.editNotifications{
  cursor: pointer;
}
.settingsTags-container{
  display: flex;
  flex-wrap: wrap;
  flex:1 1;
}
.user-list-header-field-tags{
  display: flex;
  color: var(--dark-gray);
  flex:1 1;
  justify-content: center;
}

.doc-info-field{
  margin: 0 5px;
  cursor: pointer;
}

.tag-info-field{
  margin: 0 5px;
  cursor: pointer;
  min-height: 20px;
}
.document-label{
  color:var(--dark-gray);
  z-index: 1;
  cursor: pointer;
}
.archive-label{
  color:var(--dark-gray);
  opacity:0.7;
  z-index: 1;
  font-size: 14px;
  margin-left: 5px;
}
.document-info{
  font-size: 18px;
  cursor: pointer;

}
.document-info-header{
  font-size:28px;
}
.document-info-field{

  display: block;
  border-radius: 6px;
  font-size: 18px;
  width:190px;
  border:1px solid gray;
  max-width: 100%;
  word-break: break-all;
}
#expiresBoolean{
  display: inline-block;
}


.document-info-notes{
  max-width: 90%;
  overflow-wrap:break-word;
  margin: auto;
}

.addPaymentButton{
  border: 1px solid var(--fill-green);
  background-color: var(--fill-green);
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
  color: white;
}
.stripeCheckout-helpText{
  margin-bottom:50px;
}
.payment-modal{
  position: fixed;
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 600px;
  width: 550px;
  min-height: 250px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing:border-box;
  top: 20%;
  left:20%;
  right:20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.addPaymentButton-cancel{
  border: 1px solid var(--fill-green);
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  color: var(--fill-green);
  font-size: 22px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}
.checkout{
    color: #303238;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;

}
.stripeCheckout-cardElement{
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}
.paymentModalShadow{

}
.addPaymentButton-submit{
  border: 1px solid var(--fill-green);
  background-color: var(--fill-green);
  border-radius: 5px;
  padding: 3px 10px;
  cursor: pointer;
  color: white;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 22px;
  margin-bottom: 20px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(0, 0, 0, 0.08);

}
.deleteCardText{
  margin-bottom: 30px;
}
.stripeCheckout-cityState{
  display: flex;
  justify-content: space-between;
}
.stripeCheckout-address{
  margin: 5px 10px 20px 10px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  padding: 10px 30px 10px 15px;

}
.stripe-info-field{
  display: flex;
  flex-grow:1;
  margin: 8px 6px;
  width: 100%;
  box-sizing: border-box;
  line-height: 14px;
  font-size: 14px;
  border:none;
  border-bottom: 1px solid #A9A9A9;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 50px;
  z-index: 1000;}
.fixed-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 50px;
    z-index: 1000;}
#backdropInternalSelect{
  height: auto;
  bottom: auto;
}
@media screen and (min-height:1050px){
  #backdropInternalSelect{
    bottom: 0;
  }
}
.modal {
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 1000px;
  width: 825px;
  min-height: 250px;
  margin: 0 auto;
position: relative;}
.fixed-modal {
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 1000px;
  width: 825px;
  min-height: 250px;
  margin: 0 auto;
position: relative;}
#modalExtraWide{
  width: 85%;
  max-width: 90%;
}
  .modal-title {
    text-align: center;
    font-size: 36px;
    padding-top: 30px;
    color: black;
  font-weight: bold;}
  .modal-body {
    padding: 15px;
    font-size: 20px;
    white-space: normal;
  text-align: center;}

.modal-close{
  position: absolute;
  top: 10px;
  right: 10px;
  color: var(--dark-gray);
  border: none;
  background-color: none;
  font-size: 20px;
  cursor:pointer;
}
.modal-button{
  padding: 8px 20px;
  margin: 20px auto;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor:pointer;
}

.topTag-container{

  border: var(--border-gray);
  border-radius: var(--container-corners);
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  padding-left: 15px;
  margin-bottom: 5px;
}
.topTag-container:hover{
  background-color: var(--fill-green);
  color: var(--white);
}
#notAccessibleTag{
  color:gray;
}

.topTagList{
  display: flex;
  flex-wrap: wrap;
}
.TagNavigator-container{
  background-color: white;
  padding: 10px;

}

#topTags-header{
  height: 90px;
  vertical-align: middle;
  padding:5px;
}
.helpText{
  opacity: 0.5;
  text-align: center;
  line-height: 40px;
}

.button-tagnavigator-addtag{
  display: flex;
  align-items: center;
}
.topTags-headerText-selectTag{
  margin-left: 50px;
  white-space: nowrap;
}


.topTags{
  display: flex;
  flex-direction: column;
  flex:1 1;
}
.topTag-seeMore{
  cursor:pointer;
  opacity: .6;
  align-self: flex-end;
}
.topTags-headerText{
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.search-container{
  position:relative;
}
.search {
  height: 36px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  border: var(--border-gray);
  padding-left: 35px;
  font-size: 12px;
  outline: none;
  margin-bottom: 5px;

}

.search-icon {
  height: 24px;
  margin-left: 4px;
  position: absolute;
  padding: 6px;
  opacity: 0.3;
  top: 2px;
  display: inline-block;
}

.search-clear {
  position: absolute;
  top:3px;
  right: 10px;
  line-height: 36px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5;
  color: black;

}

.tagDictionary-alphabet {
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  margin-top: 3px;
}

.tagDictionary-topTagButton {
  cursor: pointer;
}

.tags-dictionary-header {
  box-sizing: border-box;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  font-size: 18px;
  color: white;
  background-color: var(--fill-green);
  line-height: 40px;
}

.tagDictionary-headerButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: var(--dark-gray);
}

.tagDictionary-toggleSwitch {
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.tagDictionary-container {
  margin-top: 8px;
}

.tagDictionary-filterLabel {
  margin-right: 3px;
}

#activeTextGreen {
  color: var(--fill-green);
}

.toggleSwitchContainer{
  width: 30px;
  height: 15px;
  display: flex;
  background-color: var(--light-gray);
  border:var(--border-gray);
  border-radius: 15px;
  padding: 1px;
}
.activeSwitch{
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background-color: var(--white);
  align-self:flex-end;
}
.inactiveSwitch{
  width: 15px;
  border-radius: 15px;
  align-self:flex-start;
  height: 15px;
  background-color: var(--white);
}
#activeSwitch{
  flex-direction: row-reverse;
  background-color: var(--fill-green);
}
#inactiveSwitch{
  flex-direction: row;
}

#activeLetter{
  color: var(--fill-green);
  font-weight: bold;
}

.dictionaryEntry{
  cursor: pointer;
  color: var(--dark-gray);
  margin: 0px 2px;
  line-height: 18px;
}

.pinnedTagHeader{
  color:var(--dark-gray);
  font-size: 14px;
}
.pinnedTagHelp{
  font-size: 12px;
  color:var(--dark-gray);
  text-align: center;
}

.TagAccessModalContents{
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  color: black;
}
.TagAccessModalContents-tagsDictionary{
  width: 50%;
  border-right: var(--border-gray);
  padding: 10px;
  box-sizing:border-box;
}
.TagAccessModalContents-availableUsers{
  width: 50%;
  padding: 10px;
  max-height: 1000px;
  overflow-y: auto;
}
.tagAccess-activeManipulation{
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--fill-green);
  color:black;
}
.tagAccess-buttonsContainer{
  display: flex;
  flex-direction: column;
}
.tagAccess-activeUsers{
  width: 40%;
  box-sizing: border-box;
  padding: 5px;
}
.tagAccess-activeTagsContainer{
  width: 40%;
  padding: 5px;
  display:flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}
.tagAccess-activeTags{
  min-width: 100px;
  display:flex;
  flex-wrap:wrap;
}
.IndividualUserTagsAccess-information{
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  justify-content: space-between;
}
.ToggleFullTagAccessButton{
  display: flex;
  font-size: 14px;
  color: var(--dark-gray);
  cursor:pointer;
  align-items:center;
  justify-content: center;
}
.UserTagsAccess-helpText{
  font-size: 12px;
  color: var(--dark-gray);
}
.IndividualUserTagsAccess-container{
  margin-bottom: 20px;
  cursor: pointer;
}
.SelectedUserTagAccess-info{
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  justify-content: space-between;
}
.SelectedUserTagAccess-rightSide{
  display: flex;
  flex-wrap: nowrap;
}
.tagAccess-removeSelectedUser{
  cursor: pointer;
  color: var(--dark-gray);
  margin-left: 5px;
}
.darkGrayButton{
  background-color: var(--dark-gray);
  color: white;
}
.selectedTag-tagAccessContainer{
  display: flex;
  flex-wrap: nowrap;
    border: var(--border-gray);
    background-color: var(--light-gray);
    font-size: 12px;
    width: -webkit-min-content;
    width: min-content;
    padding: 0.5px 5px;
    border-radius: 5px;
    margin: 2px 3px;
    max-width: 100%;
    word-break: normal;
    white-space: nowrap;
    word-wrap: break-word;

}
.greenButton{
  background-color: var(--fill-green);
  color: white;
}
.tagAccess-removeTag{
  margin-left: 5px;
}
.changeTagAccessButton{
  border:none;
  border-radius: 5px;
  padding: 5px;
  width: 90px;
  margin: 5px 0;
}
#RemoveTagAccessButton{
  border:var(--border-gray);
}
.tagAccess-userContainer{
  min-height: 100px;
}
.TagAccess-tagsDictionaryContainer{
  font-size: 16px;
}
.tagAccess-clearButton{
  font-size: 12px;
  color: var(--fill-green);
  cursor: pointer;
  margin-left: 10px;
}
.tagAccess-activeHeaderContainer{
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.parentTag{
  background-color: var(--row-fill-gray);
  padding: 5px 0px 5px 30px;
  cursor: pointer;
}
.parentTag:nth-child(odd){
  background: var(--white)
}

.childTag{
  background-color: var(--row-fill-gray);
  padding: 2px 0px 0px 30px;
  font-size: 14px;
  cursor: pointer;
}
.childTag:nth-child(odd){
  background: var(--white)
}
.childTag:last-child{
  border-radius: 0 0 13px 13px;
}

.activeTag-container{
  border:var(--border-gray);
  background-color: var(--light-gray);
  font-size: 12px;
  width: -webkit-min-content;
  width: min-content;
  padding: 1px 5px;
  border-radius:5px;
  margin:2px 3px;
  max-width: 100%;
  word-break: normal;
  white-space:nowrap;
  word-wrap: break-word;
  height: 22px;
}
.subTags-buttonsContainer{
  display: flex;
  flex-wrap: nowrap;
}
.activeTag-header-name{
  max-width: 80%;
  word-wrap: break-word;

}
.removeTag,.addSubTag{
  cursor: pointer;
  outline:none;
  background-color: transparent;
  margin: 0 5px;
}
.subtag-header{
  font-size: 12px;

}
.tagLabel-spacer{
  display: flex;

  margin-top: 2px;
}
.activeTag-primary{
  display: flex;
  align-items: center;
}
.helpText-subtags{
  text-align: center;
  opacity: .5;
}
.subTags-container{
  display: flex;
  background-color: white;
  z-index: 10000;
  flex-wrap: wrap;
  padding: 0px 2px;
  max-width: 300px;
}
.activeTag-buttons{
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.breadcrumbs-container{
  padding:0  0 0 10px;
}

.headerButton{
  width:40px;
  margin: 2px;
  cursor: pointer;
  border:none;
  background-color: transparent;
  outline: none;
  display: flex;
}
.is-flex-column{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.buttonIcon{
  width:30px;
  height: 30px;
}


.remind-sendTemplateToModal{
  display: flex;
  flex-direction: column;
  align-items: center;
}
#buttonCreate{
  width:100px;
  color: var(--fill-green);
  font-size: 20px;

}
.input-info-field{
  width:80%;
  height:30px;
  border-radius: 3px;
  border:1px solid var(--dark-gray);
}
#buttonCreateTag{
  width:50px;
  color: var(--fill-green);
  font-size: 12px;

}
#buttonTag{
  width:40px;
  height: 35px;
}
.buttonUpload{
  width:90px;
  color: var(--white);
  height: 40px;
  text-align: center;
  background-color: var(--fill-green);
  border-radius: 3px;
  font-size: 20px;
  border: none;
}
#buttonReUpload{
  width: 45px;
}
#addSubTag-container{
  width: 100%;
}
.buttonUpload{
  text-decoration: none;
  outline:none;
  margin: 0 5px;
  line-height: 40px;
}
#buttonAddSubTag{
  font-size: 12px;
  width: 100%;
  opacity: .6;
}
#buttonAddSubTagTagsPage{
  color: white;
  font-size: 18px;
}

.helpText-CreateTagButton{
  color:black;
}

.document-upload-icon{
  width: 30px;
}
#buttonReUpload{
  width: 45px;
}
.file-dropzone-text#ReUploadButton{
  padding: 20px;
}
.button-modalText{
  text-align: center;
}
.multiTagButton-tagNameList{
  list-style:none;
}
.multiTagButton-container{
  display: flex;
  flex-direction: row;

}
.buttonDocumentsFromTags{
  background-color: transparent;
  outline:none;
  border: none;
  width: 40px;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  padding-top:1px;
}
.multiTagButton{
  outline:none;
  margin: 10px 10px 0 10px;
  width: 40%;
  height:40px;
  font-size: 16px;
  border-radius: var(--container-corners)
}
#buttonSendTo{
  width:130px;
  font-size: 14px;
  color: white;
  border-radius: 3px;
  height:30px;
  background-color: var(--fill-green);
  align-self: baseline;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.sendTemplateButton-spacer{
  height: 25px;
}
.input-template-id-field{
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--dark-gray);
  height: 30px;
}
#buttonSendDocumentTo{
  width:130px;
  font-size: 14px;
  color: white;
  border-radius: 3px;
  height:30px;
  line-height: 30px;
  background-color: var(--fill-green);
  align-self: baseline;
  text-align: center;
  justify-content: center;
}
#buttonSendDocumentToIcon{

  width:30px;
  margin-right: 7px;

}
.template-send-info-field{
  border-radius: 8px;
  border: 1px solid gray;
  min-height: 80px;
  max-height: 150px;
  min-width: 200px;
  max-width: 80%;
}
.template-send-info-field-tags{
  border-radius: 8px;
  border: 1px solid gray;
  height: 80px;
  resize: horizontal;
  min-width: 200px;
  max-width: 80%;
}
.buttonCreateTemplate{
  border-radius: 3px;
  background-color: var(--fill-green);
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 14px;
  height:40px;
  border: none;
}

.TagsSuggestionRow{
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
}

.react-tagsinput-input{
  width: 90%;
  text-align:center;
}
.react-tagsinput{
  padding:2px;
  border-radius: 8px;
}
.react-tagsinput--focused{
  padding:2px;
  border-radius: 8px;
}
.react-autosuggest__suggestions-list{

  list-style-type: none;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-left: 0px;
}
.react-autosuggest__suggestion--highlighted {
  border: 1px solid var(--fill-green);

}

.pinTagButton{
  width: 15px;
  height: 15px;
  margin-right: 2px;
  align-self: center;
  cursor: pointer;
}

.activeTags-container{
  background-color: white;
  margin: 10px;
  padding: 10px 20px;
  flex:1 1;
}
.activeTagsHeader{
  position:relative;
  font-size: 24px;
}
.activeTagList-container{
  padding:10px;
  display: flex;
  flex-wrap: wrap;
}
#activeTags{
  height: 40px;
}
.buttonClearTags{
  border: none;
  background-color: transparent;
  position: absolute;
  right:0px;
  top:10px;
  cursor: pointer;
  outline:none;
  color:var(--fill-green);
}

.ContactsSuggestionRow{
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 0 10px;
  color: black;
}
.ContactsSuggestionRow-leftContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ContactsSuggestionRow-name{
  font-weight: bold;
}
.ContactsSuggestionRow-organization{
  font-size: 14px;
  padding-left:10px;
}
.ContactsSuggestionRow-email{
  list-style-type: none;

}
.react-tagsinput-input{
  width: 90%;
  text-align:center;
}
.react-autosuggest__suggestions-list{
  list-style-type: none;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-left: 0px;
}
.react-autosuggest__suggestion--highlighted {
  border: 1px solid var(--fill-green);
  color:white;
}
.react-tagsinput--focused{
  padding: 0;
}

.referralInputs{
  display: flex;
  align-items: center;
}
.referralInputs-item{
    margin: 0 20px;
}

.referralItem-container{
  display: flex;
  flex-direction: row;
  min-width: 400px;

}
.referralItem-borderContainer{
  border-bottom: var(--border-gray);
}
.referralItem-attribute{
  width: 200px;
}
#referralButton{
  align-self: flex-end;
  margin-bottom: 0;
}
.referrals-subheading{
  margin-top: 20px;
  font-size: 20px;
}

.smallButton {
    width: 75px;
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    margin: 3px;
}

.recipeSettings-inputContainer {
    width: 400px;
}

.smallButtonContainer {
    display: flex;
}

.smallButton-secondary {
    background-color: darkgray;
    color: white;
}

.recipeSetting-container {
    margin: 10px 0;
}

.recipeSetting-title {
    font-size: 20px;
}
.columns {
  margin: auto;
  display: flex;
  align-self: center;
  max-width: 1500px;
  justify-content: center;
  width: 1250px;
  flex-direction: row;
}

.addRecipe-spacer {
  margin-top: 10px;
}

.left-documents {
  order: 1;
  flex-grow: 0;
  min-height: 100vh;
  width: 250px;
}

.navigation-selectedTagsContainer {
  display: flex;
}

.batchSheetIndicator {
  display: flex;
}

.left-documents-tags {
  min-height: 400px;
  background-color: white;
}

.navigation-docs-tableheader-tags {
  width: 300px;
}

.middle-documents {
  order: 2;
  background-color: white;
  margin: 10px;
  width: 750px;
  max-width: 1000px;
}

.right-documents {
  order: 3;
  width: 270px;
}

.navigation-header {
  box-sizing: border-box;
  padding: 0px 20px 5px 20px;
  display: flex;
  justify-content: flex-end;
}

.middle-documentsTitle {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  font-size: 24px;
}

.left-documents-tags-header {
  margin-top: 10px;
  background-color: white;
  display: flex;
  font-size: 24px;
  padding: 10px 10px 0 10px;
  justify-content: space-between;
}

.selectedDocText {
  order: 2;
  color: var(--dark-gray);
  margin-left: 3px;
  white-space: nowrap;

}

.documents {
  padding: 0 1% 1% 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.navigation-docs-table {
  display: flex;
  flex-wrap: nowrap;
  color: var(--dark-gray);
  font-size: 14px;
  border-bottom: var(--border-gray);
  padding: 0 15px;

}

.navigation-docs-tableheader-name {
  width: 300px
}

.navigation-docs-tableheader-expires {
  width: 100px;
}

.documents-SearchContainer {
  max-width: 80%;
  flex-grow: 1;
  margin-left: 15px;
}

.clearSelectedButton {
  color: var(--dark-gray);
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none
}

.middle-container {
  order: 2;
}

.button-information {
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;

}

.sortBy {
  cursor: pointer;
  color: var(--fill-green);
  font-size: 14px;
}

.results-container {
  display: flex;
  padding: 10px 20px 0 20px;
  justify-content: space-between;
  outline: none;
  color: #86888A;
}

.resultsShown {
  outline: none;
  display: flex;
  font-size: 14px;
}

.results-numResults {
  display: flex;
}

.resultsShown-selector {
  border-color: var(--fill-green);
  outline: none;
  margin-left: 10px;
  margin-right: 4px;
}

.preview{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.45);
  padding: 50px;
  z-index: 9999999;
  color: black;
  display: flex;
  flex-direction: column;
  background-size: cover;
  box-sizing: border-box;
}
.imagePreviewer-container{
  max-width: 80%;
  max-height:80%;
  margin: 0 auto;
}
.preview-png-previewer{
  display: flex;
  justify-content: center;
}
.button-preview-documentViewer{
  order:1;
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--fill-green);
  align-self: center;
  width: 40px;
  height: 40px;
}
.preview-errorText{
  color: white;
}
.preview-png{
  z-index: 9999999;
  position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
}
.closePreview{
  background-color: transparent;
  border:none;
  font-size: 20px;
  position: absolute;
  color: white;
  top:20px;
  right: 20px;
}
.button-closeDocPreview{
  order:3;
  margin-left:8px;
  cursor: pointer;
  outline: none;
  }
div.pg-viewer-wrapper{
  overflow-y: auto;
}
.react-pdf__Page__canvas{
  margin: auto;
}
.button-preview-templates{
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--dark-gray);
  margin: 0 7px;
}
.button-preview{
  order:1;
  outline: none;
  cursor:pointer;
  display: inline-block;
  color: var(--fill-green);
  align-self: center;
}
.pdf-pages{
  display: flex;
  align-items: center;
  margin: 0 auto;
  align-self: center;
  color: white;
  vertical-align:middle;
  z-index: 100;
}
.pdfPageButtons{
  width: 30px;
  margin: 5px;
}
.previewHelpText{
  text-align: center;
  color: white;
  opacity: .6;
}
.pdfCloseButton{
  margin: 10px auto;
  color: white;
}
.loadingPDFMessage{
  color: white;
  text-align: center;
  vertical-align: center;
}

.documents-preview{
  margin: 10px;
  padding: 5px;
  background-color: var(--white);
}
.documents-preview-header{
  height: 40px;
  padding: 5px;
  display: flex;
  flex-direction: row-reverse;
  color: black;
}
.buttons-docPreview{
  display: flex;
}
.recipePreview-numIngredients{
  text-align: center;
}
.recipePreview-ingredients{
  font-size:14px;
}
.recipePreview-ingredients-container{
  margin:10px 0;
}
.documents-preview{
  min-height: 100vh;
}
.preview-previewer{
  height: 100%;
}
.documents-preview-name{
  font-size: 24px;
  margin-left: 5px;
}
.documents-preview-body{
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.docPreview-tagscontainer{
  display: block;
}
.button-documentView{
  float: right;
  margin-top: 10px;
  opacity: .5;
  cursor: pointer;
  outline:none;
  white-space: nowrap;

}


.button-documentView-header{
  order:1;
  margin-left:10px;
  display: inline-block;
  text-decoration: none;
  color: var(--fill-green);



}
.button-documentView-footer{
  color:black;
  opacity: .6;
  margin-top: 20px;
  align-self: flex-end;
  text-decoration: none;
}
.docPreview-date-created{
  align-self: center;
}

.sendDocuments-urgentCheckbox{
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.send-message-field{
  border: 1px solid var(--dark-gray);
  border-radius: 6px;
  max-width: 100%;
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
}
.sendDocumentTo-DocumentOptions{
  margin-left: 10px;
  border-left: var(--border-gray);
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 40%;
  width: 40%;
}
.sendDocumentTo-composerContainer{
  max-width: 60%;
  text-align: left;
}
.sendDocumentsTo-modalContainer{
  display: flex;

}
#buttonSendDocumentToIcon{
  height:30px;
  width:30px;
}
#send-message-message{
  height: 100px;
}
.sendDocuments-subjectLine{
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
.sendToSelected-docContainer{
  width: 100%;
  border-bottom: var(--border-gray);
  margin: 4px;
  font-size: 14px;
}
.sendToSelected-sendStatus{
  display: flex;
  font-size: 12px;
  color:var(--dark-gray);
  justify-content: center;
  align-items: baseline;
}
.sendToSelected-infoContainer{
  display: flex;
  justify-content: space-between;
}

.sentTo-date{
  color: var(--dark-gray);
  font-size: 14px;
}

.timelineItem-container{
  color: black;
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 15%;
  width: 100%;
}
#timeline-topRow{
  flex-direction: column-reverse;
  bottom:0;
}
.buttonHideTimeline{
  font-size: 20px;
  cursor: pointer;
  color: var(--fill-green);
  margin-bottom:10px;
}
.timeline-hiddenMonth{
  font-size: 16px;
}
.timelineItem-name{
  font-size: 14px;
  color: black;
}
.timeline-header{
  font-size: 30px;
  color: var(--fill-green);
}
.timelineItem-date{
  font-size: 12px;
  color: var(--dark-gray);
  }
.timeline-monthName{
  color:var(--fill-green);
  cursor: pointer;
}

.timelineItem-containerHidden{
  color: white;
  font-size: 14px;
  flex-direction: column;
  max-width: 15%;
}
.timeline-timelineContainer{
  display: flex;
  flex-direction: column;
}
.timeline-timeline{
  width: 100%;
  border: 1px solid black;

}
.timeline-OtherDocs{
display: flex;
margin: 10px 0;
justify-content: space-between;
}
.timeline-topRow, .timeline-bottomRow{
  position: relative;
}

.TimeDiff-container{
  color:black;
  font-size: 18px;
  margin-bottom: 40px;
}
.TimeDiff-title{
  font-size: 22px;
}
.smallTimeDiff-container{
  
}

.expirationSearchManagementButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 12px;
  color: var(--dark-gray);
}
.expirationSearchMenu{
  min-width: 205px;
}
.advancedSearchMenu-container{
  position: absolute;
  font-size: 14px;
}
.advancedSearchMenu-left{
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: var(--border-gray);
  padding-right: 10px;
}
.button-closeAdvanced{
  cursor: pointer;
  font-weight: bold;
  align-self: flex-start;
}
.SentToDictionary-container{
  display: flex;
  align-items: baseline;
}
#selectedAdvancedSearch{
  color:var(--fill-green);
}
.originalSenderSeparator{
  border-bottom: var(--border-gray);
  margin: 3px;
  width: 100%;
}
.SentToDictionary-all{
  color: var(--dark-gray)
}
.advancedSearchMenu{
  position: absolute;
  background-color: white;
  padding: 10px;
  z-index: 100;
  min-width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
}
.advancedSearchButton-text{
  font-size: 14px;
  color:var(--dark-gray);
  cursor:pointer
}
.sentToFilter-container{
  padding: 0 10px;
  font-size: 12px;
}
.expirationSearchInput{
  font-size: 12px;
}
.expirationSearch-label{
  color: var(--dark-gray);
  font-size: 12px;
  cursor: pointer;
}

.ingredientNavRow-container {

	border-bottom: var(--border-gray);
	font-size: 16px;
	align-items: center;
	display: flex;
	flex-direction: row;
	padding: 5px 10px;
	justify-content: space-between;
}

.ingredientRow-container {

	border-bottom: var(--border-gray);
	font-size: 16px;
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	justify-content: space-around;
}

.ingredientRow-info {
	display: flex;
	justify-content: space-between;
}

.ingredient-name {
	width: 300px;

	min-width: 200px;
}

.ingredient-recipe-viewer-name {
	cursor: pointer;
	color: var(--fill-green);
}

.ingredient-percent {
	width: 100px;
	min-width: 100px;
}

.ingredient-tags {
	width: 300px;
	word-break: ;
}

.ingredient-cost {
	width: 100px;
}

.ingredient-expand {
	width: 100px;
}

.ingredient-expanded {
	padding: 0 10px;
}

.ingredient-recipe-documents {
	color: var(--fill-green);
	cursor: pointer;
	display: flex;
}

.arrow-down {
	margin: 8px 0 0 6px;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	cursor: pointer;
	border-top: 10px solid var(--fill-green);
}

.arrow-right {
	margin: 5px 0 0 8px;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;

	border-left: 10px solid var(--fill-green);
	cursor: pointer;
}
.relatedTagNavigator-container {
    width: 50%;
    margin: 10px;
    padding: 10px 20px;
}

.relatedTagDocuments-searchContainer {
    display: flex;
}

.relatedTags-header {
    font-size: 24px;
}
.batchSheetInput-name{
  width: 100%;
  border-radius: 3px;
  border:1px solid var(--dark-gray);
  height:40px;
  font-size:16px;
}
.batchSheetSend-modalContainer{
  padding:0 40px;
}
.batchSheetSend-unitContainer{
  display: flex;
  align-items:center;
  margin: 0 auto;
  width:180px;
}
.unitSelector{
    margin:0 3px;
    height:25px;
    border-radius: 6px;
}
.recipe-viewer {
  margin: 20px;
  display: flex;
  flex-direction: row;
}

.recipe-main {
  background-color: white;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.recipe-cost-selector {
  display: flex;
  margin-left: 10px;
}

.recipeViewer-changeIngredientButtons {
  display: flex;
  justify-content: space-between;
}

.recipe-unit-selector {
  margin: 0 5px;
}

.recipeInfo {
  display: flex;
  flex-direction: column;
}

.relatedDocuments-buttonContainer {
  display: flex;
}

.recipeInfo-main {
  display: flex;
  justify-content: space-between;
}

.recipeInfo-right {
  display: flex;
  align-items: baseline;
  flex-direction: row-reverse;
}

.recipe-add-ingredient {
  color: var(--fill-green);
  cursor: pointer;
}

.recipe-viewer-right-box {
  display: flex;
  order: 2;
  flex-direction: column;
  min-width: 300px;
  max-width: 450px;
  margin: 0 20px;
  background-color: white;
  min-height: 40vh;
  padding: 20px;
}

.recipe-viewer-right-top-box {
  margin-bottom: 20px;
}

.recipe-title {
  font-size: 26px;
  font-weight: 500;

}

.recipe-ingredient-container {
  margin-top: 40px;
}

.recipeLock {
  width: 30px;
  height: 30px;
  color: var(--fill-green);
}

.recipe-totalWeight {
  display: inline;
}

.recipe-totalWeightComplete {
  color: var(--fill-green);
}

.recipeInfo-rightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 50%;
}

.recipeInfo-right {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.recipe-ingredient-title-section {
  display: flex;
  flex-direction: row;
}

.recipe-viewer-right-margin {
  margin: 0 20px;
}

#bom-send {
  display: flex;
  flex-direction: column;
}

.recipe-ingredient-title {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  align-items: baseline;
  line-height: 24px;
}

.recipe-ingredient-title-text {
  font-size: 24px;
}

.button-ReturnViewer {
  margin: 7px 0px;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--fill-green);
  font-size: 12px;
}
.priceTable {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.showPriceTable {
    color: var(--fill-green);
    cursor: pointer;
}

.price-totalRow {
    border-top: 1px solid gray;
}

.bom-units {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.recipes-unitChanger-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: end;
    align-items: baseline;
}

.priceTable-container {
    margin: 15px 0;
}

.recipes-unitChangerInput {
    width: 50px;
    text-align: center;
    margin: 0px 5px;
}

.recipes-unitChanger-individual {
    margin: 2px 5px;
    display: flex;
    min-width: 175px;
    align-items: baseline;
}

.priceTable-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.priceTable-header-right {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    align-items: baseline;
}

.recipes-unitChanger-button {
    background-color: var(--fill-green);
    cursor: pointer;
    color: white;
    border-radius: 3px;
    padding: 5px 6px;
}
.centered-item{
    margin: 0 auto;
}
.release-criteria-edit{
    display: block;
    width: 100px;
  border-radius: 6px;
  font-size: 18px;
  border:1px solid gray;
  max-width: 100%;
  word-break: break-all;
}
.release-criteria-cell{
    width: 100px;
}
.ingredient-addToRecipe {
    color: var(--fill-green);
    cursor: pointer;
    width: 100px;
}

.ingredientRecipe-currentlySelected {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ingredientRecipe-headerText {
    color: var(--dark-gray);
    font-size: 14px;
}

.recipe-deleteIngredient-confirm {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin: 3px;
    width: 160px;
}

.addIngredient-searchContainers {
    display: flex;
    margin-top: 30px;
    padding-top: 30px;
    border-top: var(--border-gray)
}

.addIngredient-ingredientsContainer {
    width: 70%;
    max-width: 70%;
    padding: 10px 50px 10px 50px;
    box-sizing: border-box;
}

.addIngredient-tagsContainer {
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 30%;
}

.addIngredient-tagsNavigator {}

.addIngredient-activeTags {}

.addIngredientButton {
    background-color: var(--fill-green);
    border-radius: 4px;
    color: var(--white);
    width: 80px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    height: 45px;
    margin-right: 10px;
    line-height: 35px;
}

.recipe-edit-button-confirm {
    background-color: var(--fill-green);
    color: white;
    border-radius: 4px;
    width: 75px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    height: 40px;
    margin-right: 10px;
    line-height: 40px;
}

.recipe-edit-button-cancel {
    background-color: var(--light-gray);
    border-radius: 4px;
    width: 75px;
    font-size: 16px;
    color: var(--dark-gray);
    cursor: pointer;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

#confirm-cancel-leftbutton {
    margin-right: 10px;
}
.signatures-container{
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
}
.signatures-rightUsers{
  display: flex;
  flex-direction: column;
  border-left: var(--border-gray);
  padding: 10px;
}

.signature-awaiting-text{
  font-size: 12px;
  color: var(--dark-gray);
  white-space: nowrap;
}
.signature-reminderNumber{
  font-size: 12px;
}

.statusDot{width: 8px;
    border-radius: 50px;
    height: 8px;
    margin-right: 5px;
    margin-top: 8px;}
.signatureButton-text{
  color:var(--fill-green);
  cursor: pointer;
  width: 35px;
  height: 30px;
}
.setSignaturesButton{
  cursor: pointer;
  background-color: var(--fill-green);
  border-radius: 5px;
  color: white;
  padding: 2px 5px;
}
.signature-confirmRejectComponent{
  display: flex;
  align-items: center;
  justify-content: center;
}
.manageSignatures-buttons{
  padding: 0 10px;
  font-size: 14px;
}
.signature-userComponentContainer{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  cursor: pointer;
}
.signature-SelectedUserContainer{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
}
.signature-selectedUserRight{
  display: flex;
  flex-wrap: nowrap;
}
.signatures-userComponentContainer-names{
  margin-right: 10px;
  white-space: nowrap;
}
.signature-removeUserButton{
  color:var(--dark-gray);
  margin-left: 5px;
}
.signatures-leftcurrentSignatures{
  padding: 10px;

  border-right: var(--border-gray);
}
.signature-signatureComponent{
  display: flex;
  justify-content: space-between;
}
.signature-rejectedComment{
  font-size: 12px;
  text-align: left;
  max-width: 250px;
}
.signature-comment-field{
  max-width: 250px;
  border-radius: 3px;
  border: var(--border-gray);
  width: 100%;
  min-width: 75px;
}
.signature-signatureComponent-outerContainer{
    border-bottom: var(--border-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 10px;
    padding-bottom: 10px;
}
.signature-signatureComponent-outerContainer:last-child{
    border-bottom: none;
}
.signature-signatureComponent:last-child{
  border-bottom: none;
}
.signature-signatureInfo{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-start;
}
.signature-signatureStatus{
  display: flex;
  font-size: 14px;
  align-self: flex-end;
}
.signatureSmallButton-primary{
  font-size: 12px;
  border-radius: 5px;
  padding: 1px 3px;
  background-color: var(--fill-green);
  color: white;
  cursor: pointer;
}
.signatureSmallButton-secondary{
  font-size: 12px;
  border-radius: 5px;
  padding: 1px 3px;
  border:var(--border-gray);
  margin-left: 5px;
  cursor: pointer;
}
.signature-signatureButtons{
  display: flex;
  flex-wrap: nowrap;
}
.signature-componentRightSide{
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.signatureTitle{
  white-space: nowrap;
}
.signatureList-buttonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signatureSmallButton-permissionButton{
  cursor: pointer;
  color:var(--dark-gray);
  padding: 10px;
  font-size: 12px;
}
.signatureRemoveButton{
  width: 150px;
  margin-bottom: 10px;
}
.signatureList-signatureRequestButton{
  width: 150px;
  margin-bottom: 10px;
}

.button-remindSignatureConfirmationBox{
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 100;
  font-size:14px;
}
.remindConfirmationCheckbox{
  display: flex;
  align-items: center;
}
.sendReminderSignatureButton{
  background-color: var(--fill-green);
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  width: 100px;
  cursor: pointer;
  text-align: center;
}
.sendReminderSignatureButton-Cancel{
  border:var(--border-gray);
  background-color: var(--light-gray);
  border-radius: 3px;
  padding: 2px 4px;
  margin-top: 5px;
  width: 100px;
  cursor: pointer;
  text-align: center;
}
.button-remindSignature-single{
  font-size:12px;
  border:var(--border-gray);
  border-radius: 3px;
  padding: 2px 10px;

}
.button-remindSignatureRequest{
  border:var(--border-gray);
  border-radius: 3px;
  padding: 2px 10px;
  width: 130px;
  font-size:12px;
  cursor: pointer;
}
.button-remindSignatureRequest-container{
  font-size:12px;
  position: relative;
}

.versionReport-name{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.versionReport-nameContainer{
  display: flex;
  justify-content: space-between;
}
.versionReport-dateContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}
.versionReport-Label{
  color:var(--dark-gray);
  font-size: 12px;
}
.versionReport-signatureComponent{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: space-between;
}
.versionReport-notes{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.versionReport-componentContainer{
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-bottom: var(--border-gray);
  margin-bottom: 30px;
}
.versionReport-minorName{
  font-size: 12px;
  color: var(--dark-gray);
  display: inline-block;
  margin-left: 4px;
}
.versionReport-signatures{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;

}
.versionReport-signature{
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: var(--border-gray);
}
.versionReport-signature:last-child{
  border-bottom: none;
}

.internalSelectTags{

  order: 1;
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.internal-buttonClearTags{
  border:none;
  background-color: transparent;
  cursor: pointer;
  color: var(--fill-green);
  font-size: 12px;
}
.internalSelectContainer{
  display: flex;
  
}
.internalSelectTags-header{
  display: flex;
  font-size: 24px;
  padding: 20px 20px 0 20px;
  background-color: white;
}
.internalSelectTags-header-text{
  white-space: nowrap;
  font-size: 24px;
  display: flex;
  flex-direction: column;
}
.internalActiveTags-container{
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.internalSelect-TagNavigator{
  display: flex;
  margin: 10px;
  width: 50%;
  height: 200px;
  overflow-y: auto;
  background-color: white;
}
.internalSelectDocuments
{
  order:1;
  flex-grow: 1;
  padding: 10px;
  margin: 10px;
  background-color: var(--white);
}
.internalNavigation-header{
  box-sizing: border-box;
  padding: 10px 20px;
}
.internalSelectedDocText{

  white-space: nowrap;

}
.internal-docs-tableheader-docName{
  width: 300px;
  font-size: 12px;
  color:var(--fill-green);
  cursor: pointer;
}
.internal-docs-tableheader-expires{
width: 100px;
color:var(--fill-green);
cursor: pointer;
font-size: 12px;}
.internal-docs-tableheader-tags{
  width: 300px;
  font-size: 12px;
}
.internal-docs-contents{
  overflow-y: auto;
  max-height: 400px;
}

.batchSheetButton-container {
    display: flex;
    align-items: baseline;
    margin: 10px;
}

.batchSheetButton-toggleModal {
    cursor: pointer;
    color: var(--fill-green);
    font-size: 14px;
    margin-left: 10px;
}

.billOfMaterials-container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.batchSheet-sectionHeader {
    font-size: 24px;
    border-bottom: 1px solid var(--dark-gray);
    margin: 0px 0 5px 0;
    width: 100%;
}

.BOMseparator {
    display: flex;
    width: 400px;
    justify-content: space-between;
}

.batchSheet-weightBasis {
    display: flex;
}

.batchSheet-sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0 15px 0;
}

.batchSheet-calculateWeightBasis {
    background-color: var(--fill-green);
    color: white;
    border-radius: 3px;
    cursor: pointer;
    width: 100px;
    margin: 5px;

}

.batchSheetTable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    overflow-x: auto;
    max-width: 100%;
}

.batchSheetWeightBasis {
    width: 600px;
}

.batchSheetTable-row {
    justify-content: space-between;
    align-items: baseline;
}

.batchSheetTable-row-headers {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.batchSheetTable-rowContainer:nth-child(odd) {
    background-color: var(--light-gray);
}

.BOMseparator {
    margin-top: 20px;
}

.BOM-row:nth-child(odd) {
    background-color: var(--light-gray);
}

.batchSheetButtons {
    width: 100%;
}

.batchSheet-properties {
    display: flex;

    flex-grow: 1;
    margin-left: 20px;
}

.batchSheetNav-viewerContainer {
    margin-left: 20px;
    background-color: white;
    padding: 30px;
    text-align: center;

}

.BOM-table {
    border-collapse: collapse;
}

.BOM-cell {
    padding: 2px 8px;
}

.batchSheet-field {
    width: 100px;
    min-width: 100px;
    margin-bottom: 10px;
}

.BOM-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.BOM-row-bottom {
    border-top: 3px solid gray;
}

.batchSheet-minor-field {
    width: 100px;
    min-width: 100px;
    font-size: 14px;
    color: var(--dark-gray);
}

.batchSheetHeader {
    border-bottom: 1px solid gray;
}

.batchSheetRow:nth-child(odd) {
    background-color: var(--light-gray);
}

.showDensityTableButton {
    color: var(--fill-green);
    cursor: pointer;
}

.densityTables {
    margin-top: 10px;
}

.batchSheet-bottomTables {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    justify-content: space-between;
}

.batchSheet-info-size {
    font-size: 24px;
    display: inline-flex;
}

.batchSheet-info-minor {
    display: flex;
    flex-direction: column;
    justify-content: right;
    text-align: right;
}
.batchIngredient-propertiesContainer {
    font-size: 14px;
    display: flex;
    flex-direction: column;
}
.left-box-ingredientButton{
    border-right: 1px solid var(--light-gray);
    padding: 0 5px;
}
.ingredientCreate-container{
	display: flex;
    flex-direction: row;
}
.ingredient-property-container{
    text-align: left;
    font-size:16px;
    line-height:18px;
    margin: 6px 0;
    cursor: pointer;
}
.ingredientTemplateContainer{
    padding: 0 5px;
    margin: 0 5px;
}
.ingredientTemplateContainer-title{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size:24px;
}
.template-attributeEditButtonContainer{
    display: flex;
    justify-content: space-between;
    font-size:14px;
}
.ingredientTemplateRow-container{
    border-bottom: var(--border-gray);
    margin: 0 0 5px 0;
    padding: 5px 5px;
}
#ingredientTemplate-selected{
    border:1px solid var(--fill-green);
    border-radius: 6px;
}
.ingredientTemplateRow-delete{
    font-size:14px;
    cursor:pointer;
    color:var(--fill-green)
}
.ingredient-clearCurrentTemplateSelection{
    font-size:14px;
    color:var(--fill-green);
    cursor:pointer;
}
.ingredientTemplateRow-header{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.new-template-input{
    height:30px;
    width: 100%;
    margin: 5px 0px;
}
.ingredient-template-button-new{
    font-size:16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.make-new-template-title{
    cursor: pointer;
    color:var(--fill-green)
}
.toggleDensity-checkboxContainer {
    display: flex;
    align-items: baseline;
    margin-left: 30px;
}

.density-button {
    color: var(--fill-green);
    cursor: pointer;
}

.toggleDensity-buttonContainer {
    display: flex;
    align-items: center;
    margin: 0;
}

.toggleDensity-button {
    width: 75px;
    height: 26px;
    margin: 0 3px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    align-items: baseline;
}

.button-secondary {
    background-color: darkgray;
    color: white;

}
.upload-container {
  background-color: var(--white);
  margin: 20px;
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  padding: 20px;
  }
.document-upload-contents{

    display: flex;
    flex-direction: column;
    align-items: center;

  }
  .error-file-upload{
    color:#cc3300;
  }
  .upload-signatureContainer{
    margin-top: 20px;
  }
.selectedSignatureList{
  width: 300px;
}
.documentUpload-signatureSelection{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
.document-upload-header{
  font-size: 24px;
  height:40px;
  position: relative;
}
.navigation-container {
  width: 80%;
  min-height: 700px;
  border: var(--border-green); }

.document-tree {
  min-height: 600px; }

.file-dropzone-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  text-align: center;
  width: 350px;
  flex-grow: 2;
  max-width: 40%;
  margin:0 10px;
  min-width: 350px;
vertical-align: middle;}
.folder-dropzone-container{
  display: flex;
  flex-direction: column;
  font-size: 24px;
  min-height: 200px;
  min-width: 350px;
  width: 350px;
  max-width: 40%;
  flex-grow: 1;
  margin-right: 10px;
  background-color: var(--row-fill-gray);
  border: 1px dashed gray;
  border-radius: 10px;
  color: #9B9B9B;
  align-items: center;
  justify-content:center;
}
.document-required-info{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}
.requiredInfoText{
  align-self: flex-start;
  max-width: 200px;
}

#documentsUploadActive{
  color: var(--fill-green);
  font-weight: bold;
}
.uploadClearFiles{
  ;
  text-align: center;
  cursor: pointer;
  width: 150px;

}
  .file-dropzone {
    border: 1px dashed gray;
    min-height: 200px;
    line-height: 200px;
    border-radius: 10px;
    box-sizing: border-box;

    padding: 0 30px;
  background-color: var(--row-fill-gray)}
    .file-dropzone-text {
      padding: 20px;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      font-size: 16px;
      text-align: center;
      color: #9B9B9B;
      opacity: 1; }
#onUpload{
  display: block;
  vertical-align: middle;
  width:300px;
  font-size: 24px;
  margin: 20px auto;
}
.upload-date{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
}
.upload-optional-info-header{
  margin: 20px 20px 0 0 ;

}
.upload-expiresBoolean{
  align-self: center;
}
.helpText-docCategory{
  display: inline-block;
  font-size: 14px;
  opacity: .6;
}

.upload-optional-info{
  display: flex;
  width: 100%;
  margin-top: 40px;

}
.upload-info-field{
  border-radius: 3px;
  border:1px solid var(--dark-gray);
  height: 30px;
}
.upload-tags{
  max-width: 50%;
  flex-grow: 1;
  margin: 0 30px;
}
.upload-label{
  display: block;
  font-size: 20px;
}
.upload-optionalInfo-top{
  display: flex;

}
.uploadToggleButton{
  border-radius: 6px;
  height:40px;
  font-size: 20px;
  position: absolute;
  right:10px;
  top:0;
  display: inline;
  line-height: 40px;
  color: white;
  text-decoration: none;
}

.BOM-viewerpage-container {
    display: flex;
    flex-direction: row;
}

.recipeInfo {
    display: flex;
    flex-direction: column;
}

#bom-log-container {
    display: flex;
    flex-direction: column;
}

.BOM-viewerPage-majorContainer {
    display: flex;
}

.BillOfMaterials-logs-headers {
    display: flex;
    justify-content: space-between;
}

#bom-log-majorcontainer {
    margin-left: 15px;
}
.priceInfoInput {
    display: flex;

    align-items: center;
}

.priceInfoContainer {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
}

.ingredient-used-in-container {
    overflow-y: scroll;
}

.priceInfoItem {
    margin-right: 3px;
}

.ingredient-used-in-ratio {
    display: flex;
    align-items: center;
}

.small-lock {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}
.ingredient-property-container{
    display: flex;
    align-items: center;
}
.ingredient-property-info{
    padding: 0 5px;
}
.confirm-cancel-button-container{
    display: flex;
    justify-content: space-between;
    width: 150px;
}
.ingredient-used-in{
    border-bottom: var(--border-gray);
    padding: 5px;
    cursor: pointer;
}
.ingredient-used-in-recipe{
    color:var(--fill-green);
}
.acceptedExternalRow-container{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-left: 20px;
}

.page404 {
  text-align: center; }

.privacy_policy{
  margin: 10px;
}
.termsofservice{
  margin: 10px;
}

.faq-container{
  padding: 20px 100px 20px 80px;
}
.question{
  margin-bottom: 0px;
}
.faq-quickNav{
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  padding:20px;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  flex: 0 1;
  height:350px;
}
.faq-section-header{
  margin-top: 60px;
}
.faq-quickNav-header{
  font-size: 24px;
}
.faq-quickNav-link{
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.faq-container-contents{
  display: flex;
  flex-direction: column;
  background-color: white;
  margin: 0px 0 0 260px;
  padding: 40px;
}


.tags-parents{
  order:3;
  border-left:var(--border-gray);
  max-width: 250px;
  min-width: 150px;
  flex:1 1;

}
.tags-left{
  width:600px;
  margin: 10px 20px;
  padding:20px;
  background-color: white;
  min-height: 90vh;
  max-width: 600px;
  min-width: 600px;
}
.tags-utilityButtons{
  display: flex;
}
.tagDropZone{
  border-left: var(--border-gray);
  height: 400px;
}
.button-addtag{
  font-size: 16px;
  color: white;
}
.tags-header-text{
  font-size: 24px;
  display: flex;
}
.tags-left-navigator{
  max-width: 400px;
  min-width: 250px;
  border-right: var(--border-gray);
}
.tags-children{
  order:4;
  border-left:var(--border-gray);
  max-width: 250px;
  min-width: 150px;
  flex:1 1;
}
.subtags-container{
  padding: 10px 15px;
}
.tags-middle-header{
  background-color: var(--fill-green);
  height: 40px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.tags-middle-header-text{
  font-size:24px;
}
.tags-viewer-container{
  display: flex;
  flex-direction: column;
}
.tags-left-manipulator{
  width: 400px;
  height: 100%;
}
#subtags-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#subtags-name{
  order: 1;
}
#subtags-button{
  order: 2;
}
.tags-middle-documents{
    background-color: white;
    margin: 10px 50px 10px 10px;
    padding: 20px;
    box-sizing: border-box;
}
.tags-right{
  width: 700px;
  max-width: 700px;
  min-width: 700px;
}
.tags-middle-docs-list{
  max-height: 90vh;
  overflow-y: auto;
  padding: 10px;

}
#tags-middle-logs{
  max-height:500px;
}
.modalText{
  color:black;
}
.selectedDocuments{
  margin:0 10px;
  order:2;
  display: flex;
}
.tags-left-tagsContainer{
  display: flex;
  height: 100%;
}
.tags-docs-tableheader-name{
width: 161px;
}
.tags-docs-tableheader-expires{
  margin: 0 15px 0 80px;
}
.tags-docs-table{
  border-bottom: var(--border-gray);
  color: var(--dark-gray);
  display: flex;
  font-size: 12px;
}
.selectedDocText-tags{
  font-size: 18px;
  line-height: 40px;
}
.tags-group-buttonOperations-introJScontainer{
  display: flex;
  justify-content: center;
}

#buttonDeleteTag{
  color: var(--fill-green);

  width: 70px;
  white-space: nowrap;
}
.buttonDeleteTag{
  display: flex;
  align-items: center;
  justify-content: center;
}

.tagEditor-currentlySelectedHeader{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tagEditor-currentlySelected-name{
  display: flex;
  justify-content: space-between;
}
.tagUtilityButtons{
  display: flex;
  align-items: center;
  justify-content: center;
}
.tagRenameButtons{
  font-size: 11px;
  color: var(--fill-green);
  display: flex;
  align-items: center;
  border: none;
}
.tagDropZone{
  height: 500px;
}
.saveTagRenameButton{
  color: var(--fill-green);
  cursor:pointer;
  margin-right: 10px;
  background-color: none;
  border:none;
  font-size: 12px;
  font-weight: bolder;
}
.renameTagButton{
  border: none;
  color: var(--fill-green);
  cursor: pointer;
  background-color: transparent;

}
.tagsEditor-contentHeader{
  display: flex;
  justify-content: space-between;
  border-bottom: var(--border-gray);

}
.buttonMergeTag{


  display: flex;
  align-items: center;
  justify-content: center;
}
.mergeButton{
  white-space: nowrap;
  color: var(--fill-green);
  font-size: 11px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}
.tagsEditor-tableHeader-active{
  color: var(--fill-green);
  border-bottom: 2px solid var(--fill-green);

}
.currentTagName{font-size: 24px;}
.tagsEditor-tableHeader-inactive{
  color: var(--dark-gray);

}
.tag-ancestry-label{
  color:var(--dark-gray);
  font-size: 14px;
}
.tagEditor-container{
  margin-left: 10px;
  height: 100%;

}
.tagsEditor-contents{
  overflow-y: auto;
  max-height: 75%;
}

.passwordreset-container{
  padding: 30px;
  background-color: white;
}
#email-reset{
  width:350px;
  display: block;

}
.reset-header{
  font-size: 30px;
}
.enterreset-container{
  padding:50px;
  width:50vh;
  background-color: white;
}

.batchSheetNavViewer-navbuttonContainer{
    width: 100%;
}
.batchSheetNavViewer-rightButtons-container{
    display: flex;
    align-items: center;
}
.document-viewer {
  margin: 20px;
  display: flex;
}


.document-label {
  display: block;
}

.document-viewer-infoField {
  width: 50%;
}

.document-status-sentToContainer {
  display: flex;
  justify-content: space-between;
}

.document-viewer-status {
  color: var(--dark-gray);
  margin-left: 5px;
}

.reminderButton-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.receiverStatus {
  color: var(--dark-gray);
  font-size: 12px;
}

.markOriginalSender {
  color: var(--fill-green);
  font-size: 12px;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.document-toOrganization-date {
  margin-left: 5px;
}

.document-toOrganization {
  margin-right: 5px;
  word-break: break-all;
  max-width: 200px;
}

.button-ReturnViewer {
  margin: 7px 0px;
  text-align: left;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  color: var(--fill-green);
  font-size: 12px;

}

.documentViewer-uneditableSection {
  margin-top: 15px;
}

.documentViewer-uneditableHelpText {
  font-size: 13px;
  opacity: .7;
  margin-left: 5px;
  color: var(--dark-gray);
}

.tab-top-rightButtons {

  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 10px;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.sentTo-tableHeader {
  color: var(--dark-gray);
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  border-bottom: var(--border-gray);
}

.document-viewer-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.previewer {
  font-size: 28px;
  vertical-align: -20%;
  /* Manual put to center in bar. Not ideal. */
}

.document-viewer-fields {
  order: 1;
  flex: 4 1;
  display: flex;
  flex-direction: column;
  min-width: 350px;
}

.document-viewer-midRightColumn {
  display: flex;
  order: 2;
  flex-direction: column;
  min-width: 300px;
  max-width: 450px;
  margin: 0 20px;
  background-color: white;
  min-height: 100vh;
  padding: 20px;
}

.document-viewer-rightColumn {
  display: flex;
  order: 3;
  flex-direction: column;
  width: 300px;
  background-color: white;
  padding: 20px;
}

.document-viewer-middle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  width: 40%;
  padding: 20px 40px;
}

.document-viewer-fields-header {
  font-size: 24px;
  order: 1;
  margin-bottom: 20px;
}

.document-viewer-rightColumn-header {
  display: flex;
}

.document-viewer-header-text {
  font-size: 24px;
}

.document-viewer-tabActive {
  color: var(--fill-green);
  border-bottom: 2px solid var(--fill-green);
}

.document-viewer-tabInactive {
  color: var(--dark-gray);
}

.document-tags-container .document-tags {
  font-size: 26px;
  height: 97px;
  width: 300px;
  display: inline-block;
  border: 1px solid var(--border-gray);
  border-radius: 12px;
}

.document-viewer-column-header-text {
  font-size: 26px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.logSummary,
.versionHistory {

  box-sizing: border-box;
  text-align: left;
}

.logSummary-contents {
  overflow: auto;
  max-height: 100vh;
}

.viewer-textButtons {
  order: 1;
}

.button-sendDoc {
  float: right;
  color: var(--white);
  border: none;
  background-color: transparent;
  margin: 6px 6px 0 0;
}

.document-viewer-permissions {
  overflow-y: auto
}

.document-viewer-permissions-header {
  display: flex;
  justify-content: space-between;
}

.document-versionHistoryContents {
  padding: 0 5px;
  display: flex;
  flex-direction: column;
}

.versionHistory-link {
  color: white;
  text-decoration: none;
  padding-right: 10px;
}
.SelectFromExistingButton{
  color: var(--dark-gray);
  border-radius: 10px;
  border: 1px dashed var(--dark-gray);
  background-color: var(--row-fill-gray);
  width: 250px;
  height: 100px;
  padding: 20px;
  cursor: pointer;
}
.selectedSignatureList{
  font-size: 14px;
}
.reUpload-file-dropzone {
  border: 1px dashed gray;
  height: 50px;
  width:300px;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
background-color: var(--row-fill-gray)}

#ReUploadButton{
  height:150px;
  font-size: 16px;
  line-height: 25px;

}
.reUpload-requiresSignatures-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-OptionsContainer{
  position: absolute;
  background-color: var(--white);
  padding: 8px 10px;
  display: flex;
  align-items: center;
  z-index: 50;
  font-size: 12px;
  text-align: center;
}
.download-asPDFButton{
  color: var(--dark-gray);
  background-color:var(--row-fill-gray);
  padding: 3px 6px;
  margin: 1px 2px;
  border-radius: 7px;
  width: 80px;
  cursor: pointer;
  height: 40px;
}
.download-asOriginalButton{
  color: white;
  background-color:var(--fill-green);
  padding: 3px 6px;
  margin: 1px 2px;
  border-radius: 7px;
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;

}
#buttonDownload-Icon{

  position: relative;
}
.download-closeButton{

  align-self: flex-start;
  cursor: pointer;
  margin: 0 0 5px 5px;
  width: 12px;
  text-align: center;
}

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  background-color: #000;
  opacity: 0;
  background: radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  transition: all 0.3s ease-out;
}

.introjs-fixParent {
  z-index: auto !important;
  opacity: 1.0 !important;
  -webkit-transform: none !important;
          transform: none !important;
}

.introjs-showElement,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  z-index: 9999999 !important;
}

.introjs-disableInteraction {
  z-index: 99999999 !important;
  position: absolute;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.introjs-relativePosition,
tr.introjs-showElement > td,
tr.introjs-showElement > th {
  position: relative;
}

.introjs-helperLayer {
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  background-color: #FFF;
  background-color: rgba(255,255,255,.9);
  border: 1px solid #777;
  border: 1px solid rgba(0,0,0,.5);
  border-radius: 4px;
  box-shadow: 0 2px 15px rgba(0,0,0,.4);
  transition: all 0.3s ease-out;
}

.introjs-tooltipReferenceLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: hidden;
  z-index: 100000000;
  background-color: transparent;
  transition: all 0.3s ease-out;
}

.introjs-helperLayer *,
.introjs-helperLayer *:before,
.introjs-helperLayer *:after {
  -ms-box-sizing: content-box;
   -o-box-sizing: content-box;
      box-sizing: content-box;
}

.introjs-helperNumberLayer {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  top: -16px;
  left: -16px;
  z-index: 9999999999 !important;
  padding: 2px;
  font-family: Arial, verdana, tahoma;
  font-size: 13px;
  font-weight: bold;
  color: white;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0,0,0,.3);
  background: #ff3019; /* Old browsers */ /* Chrome10+,Safari5.1+ */ /* Chrome,Safari4+ */ /* FF3.6+ */ /* IE10+ */ /* Opera 11.10+ */
  background:         linear-gradient(to bottom, #ff3019 0%, #cf0404 100%);  /* W3C */
  width: 20px;
  height:20px;
  line-height: 20px;
  border: 3px solid white;
  border-radius: 50%;
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3019', endColorstr='#cf0404', GradientType=0)"; /* IE6-9 */
  filter: "progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)"; /* IE10 text shadows */
  box-shadow: 0 2px 5px rgba(0,0,0,.4);
}

.introjs-arrow {
  border: 5px solid transparent;
  content:'';
  position: absolute;
}
.introjs-arrow.top {
  top: -10px;
  border-bottom-color:white;
}
.introjs-arrow.top-right {
  top: -10px;
  right: 10px;
  border-bottom-color:white;
}
.introjs-arrow.top-middle {
  top: -10px;
  left: 50%;
  margin-left: -5px;
  border-bottom-color:white;
}
.introjs-arrow.right {
  right: -10px;
  top: 10px;
  border-left-color:white;
}
.introjs-arrow.right-bottom {
  bottom:10px;
  right: -10px;
  border-left-color:white;
}
.introjs-arrow.bottom {
  bottom: -10px;
  border-top-color:white;
}
.introjs-arrow.bottom-right {
  bottom: -10px;
  right: 10px;
  border-top-color:white;
}
.introjs-arrow.bottom-middle {
  bottom: -10px;
  left: 50%;
  margin-left: -5px;
  border-top-color:white;
}
.introjs-arrow.left {
  left: -10px;
  top: 10px;
  border-right-color:white;
}
.introjs-arrow.left-bottom {
  left: -10px;
  bottom:10px;
  border-right-color:white;
}

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  padding: 10px;
  background-color: white;
  min-width: 200px;
  max-width: 300px;
  border-radius: 3px;
  box-shadow: 0 1px 10px rgba(0,0,0,.4);
  transition: opacity 0.1s ease-out;
}

.introjs-tooltipbuttons {
  text-align: right;
  white-space: nowrap;
}

/*
 Buttons style by http://nicolasgallagher.com/lab/css3-github-buttons/
 Changed by Afshin Mehrabani
*/
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  display: inline-block;
  padding: 0.3em 0.8em;
  border: 1px solid #d4d4d4;
  margin: 0;
  text-decoration: none;
  text-shadow: 1px 1px 0 #fff;
  font: 11px/normal sans-serif;
  color: #333;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  background-color: #ececec;
  background-image: linear-gradient(#f4f4f4, #ececec);
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  /*background-clip: padding-box;*/ /* commented out due to Opera 11.10 bug */
  border-radius: 0.2em;
  /* IE hacks */
  zoom: 1;
  *display: inline;
  margin-top: 10px;
}

.introjs-button:hover {
  border-color: #bcbcbc;
  text-decoration: none;
  box-shadow: 0px 1px 1px #e3e3e3;
}

.introjs-button:focus,
.introjs-button:active {
  background-image: linear-gradient(#ececec, #f4f4f4);
}

/* overrides extra padding on button elements in Firefox */
.introjs-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.introjs-skipbutton {
  box-sizing: content-box;
  margin-right: 5px;
  color: #7a7a7a;
}
.introjs-donebutton{
  color: black;
}
.introjs-prevbutton {
  border-radius: 0.2em 0 0 0.2em;
  border-right: none;
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #d4d4d4;
  border-radius: 0.2em;
}

.introjs-nextbutton {
  border-radius: 0 0.2em 0.2em 0;
}

.introjs-nextbutton.introjs-fullbutton {
  border-radius: 0.2em;
}

.introjs-disabled, .introjs-disabled:hover, .introjs-disabled:focus {
  color: #9a9a9a;
  border-color: #d4d4d4;
  box-shadow: none;
  cursor: default;
  background-color: #f4f4f4;
  background-image: none;
  text-decoration: none;
}

.introjs-hidden {
     display: none;
}

.introjs-bullets {
  text-align: center;
}
.introjs-bullets ul {
  box-sizing: content-box;
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}
.introjs-bullets ul li {
  box-sizing: content-box;
  list-style: none;
  float: left;
  margin: 0 2px;
}
.introjs-bullets ul li a {
  box-sizing: content-box;
  display: block;
  width: 6px;
  height: 6px;
  background: #ccc;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.introjs-bullets ul li a:hover {
  background: #999;
}
.introjs-bullets ul li a.active {
  background: #999;
}

.introjs-progress {
  box-sizing: content-box;
  overflow: hidden;
  height: 10px;
  margin: 10px 0 5px 0;
  border-radius: 4px;
  background-color: #ecf0f1
}
.introjs-progressbar {
  box-sizing: content-box;
  float: left;
  width: 0%;
  height: 100%;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  background-color: #08c;
}

.introjsFloatingElement {
  position: absolute;
  height: 0;
  width: 0;
  left: 50%;
  top: 50%;
}

.introjs-fixedTooltip {
  position: fixed;
}

.introjs-hint {
  box-sizing: content-box;
  position: absolute;
  background: transparent;
  width: 20px;
  height: 15px;
  cursor: pointer;
}
.introjs-hint:focus {
    border: 0;
    outline: 0;
}
.introjs-hidehint {
  display: none;
}

.introjs-fixedhint {
  position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  box-sizing: content-box;
  width: 10px;
  height: 10px;
  border: 5px solid rgba(60, 60, 60, 0.27);
  border-radius: 30px;
  background-color: rgba(136, 136, 136, 0.24);
  z-index: 10;
  position: absolute;
  transition: all 0.2s ease-out;
}
.introjs-hint-no-anim .introjs-hint-dot {
  -webkit-animation: none;
  animation: none;
}
.introjs-hint-dot {
  box-sizing: content-box;
  border: 10px solid rgba(146, 146, 146, 0.36);
  background: transparent;
  border-radius: 60px;
  height: 50px;
  width: 50px;
  -webkit-animation: introjspulse 3s ease-out;
  animation: introjspulse 3s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1;
  opacity: 0;
}

@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0);
                transform: scale(0);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.1);
                transform: scale(0.1);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
        opacity: 0.0;
    }
}

