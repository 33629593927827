.childTag{
  background-color: var(--row-fill-gray);
  padding: 2px 0px 0px 30px;
  font-size: 14px;
  cursor: pointer;
}
.childTag:nth-child(odd){
  background: var(--white)
}
.childTag:last-child{
  border-radius: 0 0 13px 13px;
}
