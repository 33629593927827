.timelineItem-container{
  color: black;
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 15%;
  width: 100%;
}
#timeline-topRow{
  flex-direction: column-reverse;
  bottom:0;
}
.buttonHideTimeline{
  font-size: 20px;
  cursor: pointer;
  color: var(--fill-green);
  margin-bottom:10px;
}
.timeline-hiddenMonth{
  font-size: 16px;
}
.timelineItem-name{
  font-size: 14px;
  color: black;
}
.timeline-header{
  font-size: 30px;
  color: var(--fill-green);
}
.timelineItem-date{
  font-size: 12px;
  color: var(--dark-gray);
  }
.timeline-monthName{
  color:var(--fill-green);
  cursor: pointer;
}

.timelineItem-containerHidden{
  color: white;
  font-size: 14px;
  flex-direction: column;
  max-width: 15%;
}
.timeline-timelineContainer{
  display: flex;
  flex-direction: column;
}
.timeline-timeline{
  width: 100%;
  border: 1px solid black;

}
.timeline-OtherDocs{
display: flex;
margin: 10px 0;
justify-content: space-between;
}
.timeline-topRow, .timeline-bottomRow{
  position: relative;
}
