.relatedTagNavigator-container {
    width: 50%;
    margin: 10px;
    padding: 10px 20px;
}

.relatedTagDocuments-searchContainer {
    display: flex;
}

.relatedTags-header {
    font-size: 24px;
}