.tutorialButton{
  width: 150px;
  background-color: var(--fill-green);
  color: white;
  height: 40px;
  font-size: 20px;
  border-radius: 6px;
  cursor: pointer;
  line-height: 42px;
  text-align: center;
  margin: 0 auto;

}
.tutorialOptions{
  background-color: white;
  padding: 5px 15px;
  display: flex;
  justify-content: space-between;
}
.tutorialOption{
  cursor: pointer;
  color: var(--fill-green);
}
.tutorial2Container{
margin-top: 10px}
