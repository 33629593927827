.signatures-container{
  display: flex;
  flex-wrap: none;
  justify-content: space-between;
}
.signatures-rightUsers{
  display: flex;
  flex-direction: column;
  border-left: var(--border-gray);
  padding: 10px;
}

.signature-awaiting-text{
  font-size: 12px;
  color: var(--dark-gray);
  white-space: nowrap;
}
.signature-reminderNumber{
  font-size: 12px;
}

.statusDot{width: 8px;
    border-radius: 50px;
    height: 8px;
    margin-right: 5px;
    margin-top: 8px;}
.signatureButton-text{
  color:var(--fill-green);
  cursor: pointer;
  width: 35px;
  height: 30px;
}
.setSignaturesButton{
  cursor: pointer;
  background-color: var(--fill-green);
  border-radius: 5px;
  color: white;
  padding: 2px 5px;
}
.signature-confirmRejectComponent{
  display: flex;
  align-items: center;
  justify-content: center;
}
.manageSignatures-buttons{
  padding: 0 10px;
  font-size: 14px;
}
.signature-userComponentContainer{
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  cursor: pointer;
}
.signature-SelectedUserContainer{
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
}
.signature-selectedUserRight{
  display: flex;
  flex-wrap: nowrap;
}
.signatures-userComponentContainer-names{
  margin-right: 10px;
  white-space: nowrap;
}
.signature-removeUserButton{
  color:var(--dark-gray);
  margin-left: 5px;
}
.signatures-leftcurrentSignatures{
  padding: 10px;

  border-right: var(--border-gray);
}
.signature-signatureComponent{
  display: flex;
  justify-content: space-between;
}
.signature-rejectedComment{
  font-size: 12px;
  text-align: left;
  max-width: 250px;
}
.signature-comment-field{
  max-width: 250px;
  border-radius: 3px;
  border: var(--border-gray);
  width: 100%;
  min-width: 75px;
}
.signature-signatureComponent-outerContainer{
    border-bottom: var(--border-gray);
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-bottom: 10px;
    padding-bottom: 10px;
}
.signature-signatureComponent-outerContainer:last-child{
    border-bottom: none;
}
.signature-signatureComponent:last-child{
  border-bottom: none;
}
.signature-signatureInfo{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: flex-start;
}
.signature-signatureStatus{
  display: flex;
  font-size: 14px;
  align-self: flex-end;
}
.signatureSmallButton-primary{
  font-size: 12px;
  border-radius: 5px;
  padding: 1px 3px;
  background-color: var(--fill-green);
  color: white;
  cursor: pointer;
}
.signatureSmallButton-secondary{
  font-size: 12px;
  border-radius: 5px;
  padding: 1px 3px;
  border:var(--border-gray);
  margin-left: 5px;
  cursor: pointer;
}
.signature-signatureButtons{
  display: flex;
  flex-wrap: nowrap;
}
.signature-componentRightSide{
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.signatureTitle{
  white-space: nowrap;
}
.signatureList-buttonContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signatureSmallButton-permissionButton{
  cursor: pointer;
  color:var(--dark-gray);
  padding: 10px;
  font-size: 12px;
}
.signatureRemoveButton{
  width: 150px;
  margin-bottom: 10px;
}
.signatureList-signatureRequestButton{
  width: 150px;
  margin-bottom: 10px;
}
