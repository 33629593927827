.activeTags-container{
  background-color: white;
  margin: 10px;
  padding: 10px 20px;
  flex:1;
}
.activeTagsHeader{
  position:relative;
  font-size: 24px;
}
.activeTagList-container{
  padding:10px;
  display: flex;
  flex-wrap: wrap;
}
#activeTags{
  height: 40px;
}
.buttonClearTags{
  border: none;
  background-color: transparent;
  position: absolute;
  right:0px;
  top:10px;
  cursor: pointer;
  outline:none;
  color:var(--fill-green);
}
