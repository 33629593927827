
.landing-paragraphText{
  font-size: 20px;
}
.landing-headerText{
  font-size: 32px;
}
.landing-sectionContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  margin-top: 20px;
  min-width: 620px;
}
.customerReview-header{
  font-size: 32px;
}
.customerReview{
  padding: 20px;
}
.book-demo-link{
  text-decoration: none;
  color: white;
  background-color: var(--fill-green);
  padding: 2px 8px;
  border-radius: 7px;
  font-size: 24px;
  width:min-content;
  white-space: nowrap;
  margin: 0 auto;
  cursor: pointer;
}
.landing-sectionText{
  display: flex;
  flex-direction: column;
  text-align: left;

}
.tagsBannerText{
  position: absolute;
  width: 40%;
  left: 3vw;
  top: 10vw;
  text-align: left;
}
.foldersBannerText{
  width: 40%;
  right: 3vw;
  top:10vw;
  position: absolute;
  text-align: left;
}
.landing-secondsignup{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bannerTitle{
  font-size: 32px;
  font-size: 3vw;
  color: white;
}
.bannerText{
  font-size: 16px;
  font-size: 1.5vw;
  color:white;
}
.landing-section{
  background-color: white;
  padding: 30px 10%;
  margin: 20px 5%;
  min-width: 520px;
}
.landing-sectionTextItem{
  margin-bottom: 20px;
}
.landing-sectionTitle{
  font-size: 36px;
  margin-bottom: 10px;
}
.pageContainer{
  max-width: 100%;
  text-align: center;
}

.imgGuyConfused{
    width:50%;
    margin-right: 20px;
}
.profilePic{
  border-radius: 10000px;
  width: 100%;
  height: auto;
}

.teamItem{
  max-width: 27%;
}
.landing-imageButton{
  background-color: white;
  border-radius: 10px;
  color:var(--fill-green);
  font-size: 20px;
  font-size: 1.5vw;
  height: 4vw;
  margin-top: 1vw;
  padding: 0 13px;
  border: transparent;
  line-height: .75vw;
  cursor: pointer;
}
.teamSection{
  display: flex;
  padding: 50px;
  justify-content: space-between;
}
.landing-header{
  min-width: 660px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 5%;
  position: relative;
}
.landing-banner{
  width: 100%;
}
.landing-form-button{
  font-size: 24px;
  padding: 5px 10px;
  color: white;
  border-radius: 5px;
  width: 200px;
  margin: 0 auto;
  cursor: pointer;
}
.landing-input-text{
  margin: 0 auto;
  border-radius: 8px;
  font-size: 20px;
  border: var(--border-gray);
  width: 40%;
  height: 30px;
  display: block;
}
.landing-banner-text{
  margin:0 10%;
}
@media screen and (max-width:800px){
  .landing-sectionContainer{
    flex-direction: column;
  }
  .imgGuyConfused{
      width:75%;}
  .bannerTitle{
        font-size: 24px;
      }
  .bannerText{
        font-size: 12px;
      }
  .tagsBannerText{
        left: 30px;
        top: 25%;
      }
.foldersBannerText{
        right: 30px;
        top: 25%;
      }
      .landing-imageButton{
        border-radius: 10px;
        color:var(--fill-green);
        font-size: 14px;
        height: 35px;
        padding: 0 13px;
        border: transparent;
        cursor: pointer;
      }
}
