.SingleDatePickerInput__withBorder{
  width: 100%;
}
.SingleDatePicker{
  width: 100%;
}
.datePickerContainer{
  box-sizing: border-box;
  width: 180px;
}
