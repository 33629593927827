.addPaymentButton{
  border: 1px solid var(--fill-green);
  background-color: var(--fill-green);
  border-radius: 3px;
  padding: 3px 5px;
  cursor: pointer;
  color: white;
}
.stripeCheckout-helpText{
  margin-bottom:50px;
}
.payment-modal{
  position: fixed;
  background-color: #fff;
  border-radius:  var(--container-corners);
  max-width: 600px;
  width: 550px;
  min-height: 250px;
  margin: 0 auto;
  padding: 0 50px;
  box-sizing:border-box;
  top: 20%;
  left:20%;
  right:20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.addPaymentButton-cancel{
  border: 1px solid var(--fill-green);
  border-radius: 5px;
  padding: 3px 5px;
  cursor: pointer;
  color: var(--fill-green);
  font-size: 22px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}
.checkout{
    color: #303238;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;

}
.stripeCheckout-cardElement{
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
}
.paymentModalShadow{

}
.addPaymentButton-submit{
  border: 1px solid var(--fill-green);
  background-color: var(--fill-green);
  border-radius: 5px;
  padding: 3px 10px;
  cursor: pointer;
  color: white;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 22px;
  margin-bottom: 20px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.1), 0 2px 5px rgba(0, 0, 0, 0.08);

}
.deleteCardText{
  margin-bottom: 30px;
}
.stripeCheckout-cityState{
  display: flex;
  justify-content: space-between;
}
.stripeCheckout-address{
  margin: 5px 10px 20px 10px;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);
  padding: 10px 30px 10px 15px;

}
.stripe-info-field{
  display: flex;
  flex-grow:1;
  margin: 8px 6px;
  width: 100%;
  box-sizing: border-box;
  line-height: 14px;
  font-size: 14px;
  border:none;
  border-bottom: 1px solid #A9A9A9;
}
