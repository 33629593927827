.versionReport-name{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}
.versionReport-nameContainer{
  display: flex;
  justify-content: space-between;
}
.versionReport-dateContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}
.versionReport-Label{
  color:var(--dark-gray);
  font-size: 12px;
}
.versionReport-signatureComponent{
  display: flex;
  flex-direction: row;
  width:100%;
  justify-content: space-between;
}
.versionReport-notes{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}
.versionReport-componentContainer{
  width: 100%;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border-bottom: var(--border-gray);
  margin-bottom: 30px;
}
.versionReport-minorName{
  font-size: 12px;
  color: var(--dark-gray);
  display: inline-block;
  margin-left: 4px;
}
.versionReport-signatures{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;

}
.versionReport-signature{
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: var(--border-gray);
}
.versionReport-signature:last-child{
  border-bottom: none;
}
