.activeTag-container{
  border:var(--border-gray);
  background-color: var(--light-gray);
  font-size: 12px;
  width: min-content;
  padding: 1px 5px;
  border-radius:5px;
  margin:2px 3px;
  max-width: 100%;
  word-break: normal;
  white-space:nowrap;
  word-wrap: break-word;
  height: 22px;
}
.subTags-buttonsContainer{
  display: flex;
  flex-wrap: nowrap;
}
.activeTag-header-name{
  max-width: 80%;
  word-wrap: break-word;

}
.removeTag,.addSubTag{
  cursor: pointer;
  outline:none;
  background-color: transparent;
  margin: 0 5px;
}
.subtag-header{
  font-size: 12px;

}
.tagLabel-spacer{
  display: flex;

  margin-top: 2px;
}
.activeTag-primary{
  display: flex;
  align-items: center;
}
.helpText-subtags{
  text-align: center;
  opacity: .5;
}
.subTags-container{
  display: flex;
  background-color: white;
  z-index: 10000;
  flex-wrap: wrap;
  padding: 0px 2px;
  max-width: 300px;
}
.activeTag-buttons{
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.breadcrumbs-container{
  padding:0  0 0 10px;
}
