.internalSelectTags{

  order: 1;
  display: flex;
  flex-direction: column;
  margin: 10px;
}
.internal-buttonClearTags{
  border:none;
  background-color: transparent;
  cursor: pointer;
  color: var(--fill-green);
  font-size: 12px;
}
.internalSelectContainer{
  display: flex;
  
}
.internalSelectTags-header{
  display: flex;
  font-size: 24px;
  padding: 20px 20px 0 20px;
  background-color: white;
}
.internalSelectTags-header-text{
  white-space: nowrap;
  font-size: 24px;
  display: flex;
  flex-direction: column;
}
.internalActiveTags-container{
  font-size: 16px;
  display: flex;
  flex-direction: column;
}
.internalSelect-TagNavigator{
  display: flex;
  margin: 10px;
  width: 50%;
  height: 200px;
  overflow-y: auto;
  background-color: white;
}
.internalSelectDocuments
{
  order:1;
  flex-grow: 1;
  padding: 10px;
  margin: 10px;
  background-color: var(--white);
}
.internalNavigation-header{
  box-sizing: border-box;
  padding: 10px 20px;
}
.internalSelectedDocText{

  white-space: nowrap;

}
.internal-docs-tableheader-docName{
  width: 300px;
  font-size: 12px;
  color:var(--fill-green);
  cursor: pointer;
}
.internal-docs-tableheader-expires{
width: 100px;
color:var(--fill-green);
cursor: pointer;
font-size: 12px;}
.internal-docs-tableheader-tags{
  width: 300px;
  font-size: 12px;
}
.internal-docs-contents{
  overflow-y: auto;
  max-height: 400px;
}
