.home {
  display: flex;
  background-color: var(--row-fill-gray);
  padding:20px 40px;}

.home-left{
  flex: 1;
  min-height: 91vh;
  width: 30%;
  min-width: 300px;
  background-color: white;
  overflow-y: auto;
}
.home-docs-tableheader-name{
  width: 310px;
}
.tags-docs-tableheader-ingredients{
  width:100px;
}
.home-left-changelog{
  padding: 5px 15px;
  max-height: 90vh;
  background-color: white;
}
.homeButtons{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.goMobileButton{
  background-color: var(--fill-green);
  color: white;
  text-align: center;
  border-radius:3px;
  margin:5px;
  font-size: 20px;
  height: 40px;
  width: 150px;
  cursor: pointer;
  line-height: 42px;
}
.home-middle{
  order:2;
  flex: 1;
  padding: 15px;
  margin: 0 20px;
  min-width: 300px;
  background-color: white;
}
.home-right{
  order:3;
  width:30%;
  min-width: 300px;
  background-color: white;
}
.welcomeMessage{

}
.home-container{
  height:30vh;
  overflow-y: auto;
}