.doc-info-field{
  margin: 0 5px;
  cursor: pointer;
}

.tag-info-field{
  margin: 0 5px;
  cursor: pointer;
  min-height: 20px;
}
.document-label{
  color:var(--dark-gray);
  z-index: 1;
  cursor: pointer;
}
.archive-label{
  color:var(--dark-gray);
  opacity:0.7;
  z-index: 1;
  font-size: 14px;
  margin-left: 5px;
}
.document-info{
  font-size: 18px;
  cursor: pointer;

}
.document-info-header{
  font-size:28px;
}
.document-info-field{

  display: block;
  border-radius: 6px;
  font-size: 18px;
  width:190px;
  border:1px solid gray;
  max-width: 100%;
  word-break: break-all;
}
#expiresBoolean{
  display: inline-block;
}


.document-info-notes{
  max-width: 90%;
  overflow-wrap:break-word;
  margin: auto;
}
