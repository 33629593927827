.button-remindSignatureConfirmationBox{
  background-color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 100;
  font-size:14px;
}
.remindConfirmationCheckbox{
  display: flex;
  align-items: center;
}
.sendReminderSignatureButton{
  background-color: var(--fill-green);
  border-radius: 4px;
  padding: 2px 4px;
  color: white;
  width: 100px;
  cursor: pointer;
  text-align: center;
}
.sendReminderSignatureButton-Cancel{
  border:var(--border-gray);
  background-color: var(--light-gray);
  border-radius: 3px;
  padding: 2px 4px;
  margin-top: 5px;
  width: 100px;
  cursor: pointer;
  text-align: center;
}
.button-remindSignature-single{
  font-size:12px;
  border:var(--border-gray);
  border-radius: 3px;
  padding: 2px 10px;

}
.button-remindSignatureRequest{
  border:var(--border-gray);
  border-radius: 3px;
  padding: 2px 10px;
  width: 130px;
  font-size:12px;
  cursor: pointer;
}
.button-remindSignatureRequest-container{
  font-size:12px;
  position: relative;
}
