.batchSheetButton-container {
    display: flex;
    align-items: baseline;
    margin: 10px;
}

.batchSheetButton-toggleModal {
    cursor: pointer;
    color: var(--fill-green);
    font-size: 14px;
    margin-left: 10px;
}

.billOfMaterials-container {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.batchSheet-sectionHeader {
    font-size: 24px;
    border-bottom: 1px solid var(--dark-gray);
    margin: 0px 0 5px 0;
    width: 100%;
}

.BOMseparator {
    display: flex;
    width: 400px;
    justify-content: space-between;
}

.batchSheet-weightBasis {
    display: flex;
}

.batchSheet-sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 0 15px 0;
}

.batchSheet-calculateWeightBasis {
    background-color: var(--fill-green);
    color: white;
    border-radius: 3px;
    cursor: pointer;
    width: 100px;
    margin: 5px;

}

.batchSheetTable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    overflow-x: auto;
    max-width: 100%;
}

.batchSheetWeightBasis {
    width: 600px;
}

.batchSheetTable-row {
    justify-content: space-between;
    align-items: baseline;
}

.batchSheetTable-row-headers {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.batchSheetTable-rowContainer:nth-child(odd) {
    background-color: var(--light-gray);
}

.BOMseparator {
    margin-top: 20px;
}

.BOM-row:nth-child(odd) {
    background-color: var(--light-gray);
}

.batchSheetButtons {
    width: 100%;
}

.batchSheet-properties {
    display: flex;

    flex-grow: 1;
    margin-left: 20px;
}

.batchSheetNav-viewerContainer {
    margin-left: 20px;
    background-color: white;
    padding: 30px;
    text-align: center;

}

.BOM-table {
    border-collapse: collapse;
}

.BOM-cell {
    padding: 2px 8px;
}

.batchSheet-field {
    width: 100px;
    min-width: 100px;
    margin-bottom: 10px;
}

.BOM-viewer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
}

.BOM-row-bottom {
    border-top: 3px solid gray;
}

.batchSheet-minor-field {
    width: 100px;
    min-width: 100px;
    font-size: 14px;
    color: var(--dark-gray);
}

.batchSheetHeader {
    border-bottom: 1px solid gray;
}

.batchSheetRow:nth-child(odd) {
    background-color: var(--light-gray);
}

.showDensityTableButton {
    color: var(--fill-green);
    cursor: pointer;
}

.densityTables {
    margin-top: 10px;
}

.batchSheet-bottomTables {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    min-width: 1000px;
    justify-content: space-between;
}

.batchSheet-info-size {
    font-size: 24px;
    display: inline-flex;
}

.batchSheet-info-minor {
    display: flex;
    flex-direction: column;
    justify-content: right;
    text-align: right;
}