.TagsSuggestionRow{
  list-style-type: none;
  display: inline-block;
  font-size: 16px;
  padding: 0 10px;
}

.react-tagsinput-input{
  width: 90%;
  text-align:center;
}
.react-tagsinput{
  padding:2px;
  border-radius: 8px;
}
.react-tagsinput--focused{
  padding:2px;
  border-radius: 8px;
}
.react-autosuggest__suggestions-list{

  list-style-type: none;
  padding: 0;
  list-style: none;
  width: 100%;
  margin-left: 0px;
}
.react-autosuggest__suggestion--highlighted {
  border: 1px solid var(--fill-green);

}
