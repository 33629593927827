.columns {
  margin: auto;
  display: flex;
  align-self: center;
  max-width: 1500px;
  justify-content: center;
  width: 1250px;
  flex-direction: row;
}

.addRecipe-spacer {
  margin-top: 10px;
}

.left-documents {
  order: 1;
  flex-grow: 0;
  min-height: 100vh;
  width: 250px;
}

.navigation-selectedTagsContainer {
  display: flex;
}

.batchSheetIndicator {
  display: flex;
}

.left-documents-tags {
  min-height: 400px;
  background-color: white;
}

.navigation-docs-tableheader-tags {
  width: 300px;
}

.middle-documents {
  order: 2;
  background-color: white;
  margin: 10px;
  width: 750px;
  max-width: 1000px;
}

.right-documents {
  order: 3;
  width: 270px;
}

.navigation-header {
  box-sizing: border-box;
  padding: 0px 20px 5px 20px;
  display: flex;
  justify-content: flex-end;
}

.middle-documentsTitle {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  font-size: 24px;
}

.left-documents-tags-header {
  margin-top: 10px;
  background-color: white;
  display: flex;
  font-size: 24px;
  padding: 10px 10px 0 10px;
  justify-content: space-between;
}

.selectedDocText {
  order: 2;
  color: var(--dark-gray);
  margin-left: 3px;
  white-space: nowrap;

}

.documents {
  padding: 0 1% 1% 1%;
  display: flex;
  justify-content: center;
  flex-direction: column;

}

.navigation-docs-table {
  display: flex;
  flex-wrap: nowrap;
  color: var(--dark-gray);
  font-size: 14px;
  border-bottom: var(--border-gray);
  padding: 0 15px;

}

.navigation-docs-tableheader-name {
  width: 300px
}

.navigation-docs-tableheader-expires {
  width: 100px;
}

.documents-SearchContainer {
  max-width: 80%;
  flex-grow: 1;
  margin-left: 15px;
}

.clearSelectedButton {
  color: var(--dark-gray);
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none
}

.middle-container {
  order: 2;
}

.button-information {
  border: none;
  cursor: pointer;
  outline: none;
  background-color: transparent;

}

.sortBy {
  cursor: pointer;
  color: var(--fill-green);
  font-size: 14px;
}

.results-container {
  display: flex;
  padding: 10px 20px 0 20px;
  justify-content: space-between;
  outline: none;
  color: #86888A;
}

.resultsShown {
  outline: none;
  display: flex;
  font-size: 14px;
}

.results-numResults {
  display: flex;
}

.resultsShown-selector {
  border-color: var(--fill-green);
  outline: none;
  margin-left: 10px;
  margin-right: 4px;
}