.pageButton {
  margin: 0 5px;
  display: inline-block;
  color: black;
  border: none;
  width: 30px;
  height: 30px;
  outline: none;
  background-color: white;}
.pageButton:hover{
  cursor: pointer;
}
.pageButtons {
  margin: 10px auto;
  text-align: center; }


#selectedPage{
  background-color: var(--fill-green);
  color: var(--white);
}
