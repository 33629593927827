.expirationSearchManagementButtons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font: 12px;
  color: var(--dark-gray);
}
.expirationSearchMenu{
  min-width: 205px;
}
.advancedSearchMenu-container{
  position: absolute;
  font-size: 14px;
}
.advancedSearchMenu-left{
  display: flex;
  align-items: center;
  flex-direction: column;
  border-right: var(--border-gray);
  padding-right: 10px;
}
.button-closeAdvanced{
  cursor: pointer;
  font-weight: bold;
  align-self: flex-start;
}
.SentToDictionary-container{
  display: flex;
  align-items: baseline;
}
#selectedAdvancedSearch{
  color:var(--fill-green);
}
.originalSenderSeparator{
  border-bottom: var(--border-gray);
  margin: 3px;
  width: 100%;
}
.SentToDictionary-all{
  color: var(--dark-gray)
}
.advancedSearchMenu{
  position: absolute;
  background-color: white;
  padding: 10px;
  z-index: 100;
  min-width: 400px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
}
.advancedSearchButton-text{
  font-size: 14px;
  color:var(--dark-gray);
  cursor:pointer
}
.sentToFilter-container{
  padding: 0 10px;
  font-size: 12px;
}
.expirationSearchInput{
  font-size: 12px;
}
.expirationSearch-label{
  color: var(--dark-gray);
  font-size: 12px;
  cursor: pointer;
}
