.tagLabel-container{
  border:var(--border-gray);
  background-color: var(--light-gray);
  font-size: 12px;
  width: min-content;
  padding: 0.5px 5px;
  border-radius:5px;
  display: inline-block;
  margin:2px 3px;
  max-width: 100%;
  word-break: normal;
  white-space:nowrap;
  word-wrap: break-word;
}
