.externalDocs-container{
background-color: white;
padding: 20px;
margin: 20px;
overflow-y: auto;
}
.external-sharedWith{

}
.externalDocs-content{
  max-height: 500px;
  overflow-y: auto;
  padding-right: 80px;
}
.pendingExternalRow-container{
  display: flex;
  align-items: baseline;
  font-size: 14px;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  border-bottom:var(--border-gray);
}
.externalDoc-name{
  cursor: pointer;
}
.externalDocs-filters{
  display: flex;
  margin-top: 10px;
  align-items: baseline;
}
.externalDocs-selectUser{
  margin-left: 5px;
  height: 20px;
  background-color: white;
  border: 1px solid var(--fill-green);

}
