.sendDocuments-urgentCheckbox{
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.send-message-field{
  border: 1px solid var(--dark-gray);
  border-radius: 6px;
  max-width: 100%;
  padding: 2px;
  box-sizing: border-box;
  width: 100%;
}
.sendDocumentTo-DocumentOptions{
  margin-left: 10px;
  border-left: var(--border-gray);
  display: flex;
  flex-direction: column;
  padding: 10px;
  max-width: 40%;
  width: 40%;
}
.sendDocumentTo-composerContainer{
  max-width: 60%;
  text-align: left;
}
.sendDocumentsTo-modalContainer{
  display: flex;

}
#buttonSendDocumentToIcon{
  height:30px;
  width:30px;
}
#send-message-message{
  height: 100px;
}
.sendDocuments-subjectLine{
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
}
.sendToSelected-docContainer{
  width: 100%;
  border-bottom: var(--border-gray);
  margin: 4px;
  font-size: 14px;
}
.sendToSelected-sendStatus{
  display: flex;
  font-size: 12px;
  color:var(--dark-gray);
  justify-content: center;
  align-items: baseline;
}
.sendToSelected-infoContainer{
  display: flex;
  justify-content: space-between;
}
