.footer-unauth {
  height: 52px;
  background-color: #EFEFEF; }
  .footer-unauth .footer-links {
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    line-height: 52px;
    font-size: 14px;
    color: #BCB9BD; }
  .footer-unauth a {
    width: 250px;
    text-align: center;
    text-decoration: none;
    color: #BCB9BD; }
    .footer-unauth a:hover {
      cursor: pointer; }
  .footer-unauth .copyright {
    right: 5px;
    position: absolute; }

.footer-auth {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 30px;
  background-color: #EFEFEF;}

  .footer-auth .footer-links {
    width: 40%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    font-size: 14px;
    color: #BCB9BD;
  white-space: nowrap;}
  .footer-auth a {
    width: 250px;
    text-align: center;
    text-decoration: none;
    color: #BCB9BD; }
    .footer-auth a:hover {
      cursor: pointer; }
  .footer-auth .copyright {
    right: 5px;
    position: absolute; }
.footer-buffer{
  width: 100%;
  height:30px;
}
