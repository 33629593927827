.topTagList{
  display: flex;
  flex-wrap: wrap;
}
.TagNavigator-container{
  background-color: white;
  padding: 10px;

}

#topTags-header{
  height: 90px;
  vertical-align: middle;
  padding:5px;
}
.helpText{
  opacity: 0.5;
  text-align: center;
  line-height: 40px;
}

.button-tagnavigator-addtag{
  display: flex;
  align-items: center;
}
.topTags-headerText-selectTag{
  margin-left: 50px;
  white-space: nowrap;
}


.topTags{
  display: flex;
  flex-direction: column;
  flex:1;
}
.topTag-seeMore{
  cursor:pointer;
  opacity: .6;
  align-self: flex-end;
}
.topTags-headerText{
  display: flex;
  align-items: center;
  justify-content: space-between;

}
