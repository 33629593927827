
.document-row{
	display: flex;
	min-height: 35px;

	padding: 3px 10px ;
	border-bottom: var(--border-gray);
	align-items: center;
	justify-content: space-between;

}
.document-row-short{
	display: flex;
	min-height: 30px;
	border-radius: var(--container-corners);
	padding: 3px 15px 5px 10px ;
	border:var(--border-gray);
	justify-content: space-between;
}
#document-row-active{

	border:2px solid var(--fill-green);
	border-radius: 5px;

}
.expiredDot{
	background-color: #E65531;
	width: 6px;
  border-radius: 50px;
  height: 6px;
	margin-right: 3px;
	min-width: 6px;
	max-width: 6px;
	min-height: 6px;
	max-height: 6px;
}
.document-row-info{
	display: flex;
	flex-direction: column;
	width: 300px;
}
.document-row-name{
	order: 1;
	font-size:14px;
}
.short-document-row-name{
	order: 1;
	font-size:14px;
	line-height: 18px;
}
.document-row-id{
	order:2;
	font-size: 12px;
	color: var(--dark-gray);
}
.document-tag-container{
	line-height: 16px;
	width: 300px;
}
.document-row-category{
	font-size: 24px;
	line-height: 24px;
}
.short-document-row-category{
	font-size: 14px;
	line-height: 18px;
}

.document-expiration-date{
	font-size: 12px;
	display: flex;
	align-items: center;
	width: 100px;
}
.short-document-expiration-label{
	font-size: 14px;
}
.short-document-row-right{
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.button-document-row-infoViewer{
	font-size: 12px;
	width: 35px;
	align-self: center;
	cursor: pointer;
	line-height: 14px;
}
.document-short-row-left{
	display: flex;
	flex-direction: column;
}
