.ShortLogsRow-container{
  padding: 0 10px;
  margin: 6px 5px 0px 6px;;
  min-height: 40px;
  border-bottom: var(--border-gray);
  font-size: 14px;
  font-weight: 300;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.shortLogs-description{
   flex:1;
}
.shortLogs-timestamp{
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.shortLogs-messageContainer{
  flex-wrap: wrap;
  display: flex;
  flex:1;
  max-width: 30%;
}
.log-text-info{
  color:var(--dark-gray);
}
.log-text-noteButton{
  color:var(--fill-green);
  cursor: pointer;
}
.log-circle{
  height:8px;
  width:8px;
  border-radius: 100px;
  background-color: var(--fill-green);

}
.log-notes-field{
  width: 100%;
  resize: vertical;
  margin-bottom: 5px;
}
.log-decoration{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0 0 7px;
  box-sizing: border-box;
}
.log-line{
  border-left: var(--border-gray);
  flex-grow: 1;
  width:1px;
  box-sizing: border-box;
}
.log-line-short{
  border-left: var(--border-gray);
  height: 12px;
  width:1px;
  box-sizing: border-box;
}

.summaryLogsRow-container{
  display: flex;
}
