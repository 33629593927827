.search-container{
  position:relative;
}
.search {
  height: 36px;
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  border: var(--border-gray);
  padding-left: 35px;
  font-size: 12px;
  outline: none;
  margin-bottom: 5px;

}

.search-icon {
  height: 24px;
  margin-left: 4px;
  position: absolute;
  padding: 6px;
  opacity: 0.3;
  top: 2px;
  display: inline-block;
}

.search-clear {
  position: absolute;
  top:3px;
  right: 10px;
  line-height: 36px;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5;
  color: black;

}
