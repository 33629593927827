.ingredient-property-container{
    display: flex;
    align-items: center;
}
.ingredient-property-info{
    padding: 0 5px;
}
.confirm-cancel-button-container{
    display: flex;
    justify-content: space-between;
    width: 150px;
}
.ingredient-used-in{
    border-bottom: var(--border-gray);
    padding: 5px;
    cursor: pointer;
}
.ingredient-used-in-recipe{
    color:var(--fill-green);
}