.ingredientNavRow-container {

	border-bottom: var(--border-gray);
	font-size: 16px;
	align-items: center;
	display: flex;
	flex-direction: row;
	padding: 5px 10px;
	justify-content: space-between;
}

.ingredientRow-container {

	border-bottom: var(--border-gray);
	font-size: 16px;
	display: flex;
	flex-direction: column;
	padding: 5px 10px;
	justify-content: space-around;
}

.ingredientRow-info {
	display: flex;
	justify-content: space-between;
}

.ingredient-name {
	width: 300px;

	min-width: 200px;
}

.ingredient-recipe-viewer-name {
	cursor: pointer;
	color: var(--fill-green);
}

.ingredient-percent {
	width: 100px;
	min-width: 100px;
}

.ingredient-tags {
	width: 300px;
	word-break: ;
}

.ingredient-cost {
	width: 100px;
}

.ingredient-expand {
	width: 100px;
}

.ingredient-expanded {
	padding: 0 10px;
}

.ingredient-recipe-documents {
	color: var(--fill-green);
	cursor: pointer;
	display: flex;
}

.arrow-down {
	margin: 8px 0 0 6px;
	width: 0;
	height: 0;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	cursor: pointer;
	border-top: 10px solid var(--fill-green);
}

.arrow-right {
	margin: 5px 0 0 8px;
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;

	border-left: 10px solid var(--fill-green);
	cursor: pointer;
}