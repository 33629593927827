.referralInputs{
  display: flex;
  align-items: center;
}
.referralInputs-item{
    margin: 0 20px;
}

.referralItem-container{
  display: flex;
  flex-direction: row;
  min-width: 400px;

}
.referralItem-borderContainer{
  border-bottom: var(--border-gray);
}
.referralItem-attribute{
  width: 200px;
}
#referralButton{
  align-self: flex-end;
  margin-bottom: 0;
}
.referrals-subheading{
  margin-top: 20px;
  font-size: 20px;
}
